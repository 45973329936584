import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// All available actions
import { signUpEmail, logInGoogle, logInEmail, logOut } from '../state/auth/actions';
import { addBook, getPage } from '../state/books/actions';
import { showSuccessMessage, hideSuccessMessage } from '../state/messages/actions';
import { recommendBookAction, editRecommendation, deleteRecommendation } from '../state/books/actions';
import { getProfile, setProfile, setUsername, resetState } from '../state/profile/actions';
import { userList } from '../state/users/actions';
import { getRecommendedBooks } from '../state/recommendations/actions'

function ConnectedComponent(component) {
  const mapDispatchToProps = dispatch => ({
    deleteRecommendation: bookId => dispatch(deleteRecommendation({ bookId })),
    showSuccessMessage: message => dispatch(showSuccessMessage(message)),
    recommendBook: (book, text) => dispatch(recommendBookAction(book, text)),
    editRecommendation: (book, text) => dispatch(editRecommendation(book, text)),
    getProfile: () => dispatch(getProfile()),
    saveProfile: profile => dispatch(setProfile(profile)),
    saveUsername: username => dispatch(setUsername(username)),
    logout: () => dispatch(logOut()),
    logInEmail: (email, password) => dispatch(logInEmail(email, password)),
    signUpEmail: (email, password) => dispatch(signUpEmail(email, password)),
    addBook: (title, subtitle, author, recommendation) => dispatch(addBook(title, subtitle, author, recommendation)),
    getPage: opts => dispatch(getPage(opts)),
    logInGoogle: () => dispatch(logInGoogle()),
    hideSuccessMessage: () => dispatch(hideSuccessMessage()),
    resetState: () => dispatch(resetState()),
    userList: () => dispatch(userList()),
    getRecommendedBooks: () => dispatch(getRecommendedBooks()),
  });

  return withRouter(connect(
    state => ({ ...state }),
    mapDispatchToProps,
  )(component));
}

export default ConnectedComponent;