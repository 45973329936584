import { asyncStateFunction } from '../util';
import { BooksService } from '../../services/books';
import { RecommendationsService } from '../../services/recommendations';

export const GET_BOOKS = 'GET_BOOKS';
export const GET_BOOKS_SUCCESS = 'GET_BOOKS_SUCCESS';
export const GET_BOOKS_ERROR = 'GET_BOOKS_ERROR';
export const GET_BOOKS_PAGE = 'GET_BOOKS_PAGE';
export const GET_BOOKS_PAGE_SUCCESS = 'GET_BOOKS_PAGE_SUCCESS';
export const GET_BOOKS_PAGE_ERROR = 'GET_BOOKS_PAGE_ERROR';
export const GET_RECOMMENDED_BOOKS = 'GET_RECOMMENDED_BOOKS';
export const GET_RECOMMENDED_BOOKS_SUCCESS = 'GET_RECOMMENDED_BOOKS_SUCCESS';
export const GET_RECOMMENDED_BOOKS_ERROR = 'GET_RECOMMENDED_BOOKS_ERROR';
export const ADD_BOOK = 'ADD_BOOK';
export const ADD_BOOK_SUCCESS = 'ADD_BOOK_SUCCESS';
export const ADD_BOOK_ERROR = 'ADD_BOOK_ERROR';
export const RECOMMEND_BOOK = 'RECOMMEND_BOOK';
export const RECOMMEND_BOOK_SUCCESS = 'RECOMMEND_BOOKSUCCESS';
export const RECOMMEND_BOOK_ERROR = 'RECOMMEND_BOOK_ERROR';
export const EDIT_RECOMMENDATION = 'EDIT_RECOMMENDATION';
export const EDIT_RECOMMENDATION_SUCCESS = 'EDIT_RECOMMENDATION_SUCCESS';
export const EDIT_RECOMMENDATION_ERROR = 'EDIT_RECOMMENDATION_ERROR';
export const DELETE_RECOMMENDATION = 'DELETE_RECOMMENDATION';
export const DELETE_RECOMMENDATION_SUCCESS = 'DELETE_RECOMMENDATION_SUCCESS';
export const DELETE_RECOMMENDATION_ERROR = 'DELETE_RECOMMENDATION_ERROR';


export const getPage = (opts) => asyncStateFunction(
  'booksState',
  GET_BOOKS_PAGE,
  GET_BOOKS_PAGE_SUCCESS,
  GET_BOOKS_PAGE_ERROR,
  BooksService.getBooks,
  opts);

export function addBook(title, subtitle, author, recommendation) {
  return (dispatch, getState) => {
    if (getState().booksState.loading) {
      return Promise.resolve();
    }

    dispatch({
      type: ADD_BOOK,
    });

    BooksService.addBook(title, subtitle, author, recommendation)
        .then((response) => {
          // console.log('response', response);
          dispatch({
            type: ADD_BOOK_SUCCESS,
            books: response,
          });
        })
        .catch((error) => {
          console.log('error getting books', error);
          dispatch({
            type: ADD_BOOK_ERROR,
            message: error,
          });
        });
  };
}

export const getRecommendedBooks = (opts) => asyncStateFunction(
  'booksState',
  GET_RECOMMENDED_BOOKS,
  GET_RECOMMENDED_BOOKS_SUCCESS,
  GET_RECOMMENDED_BOOKS_ERROR,
  RecommendationsService.getRecommendedBooks,
  opts);

export const recommendBookAction = (opts) => asyncStateFunction(
  'booksState',
  RECOMMEND_BOOK,
  RECOMMEND_BOOK_SUCCESS,
  RECOMMEND_BOOK_ERROR,
  RecommendationsService.recommendBook,
  opts);

export const editRecommendation = (opts) => asyncStateFunction(
  'booksState',
  EDIT_RECOMMENDATION,
  EDIT_RECOMMENDATION_SUCCESS,
  EDIT_RECOMMENDATION_ERROR,
  RecommendationsService.editRecommendation,
  opts);

export const deleteRecommendation = (opts) => asyncStateFunction(
  'booksState',
  DELETE_RECOMMENDATION,
  DELETE_RECOMMENDATION_SUCCESS,
  DELETE_RECOMMENDATION_ERROR,
  RecommendationsService.deleteRecommendation,
  opts);


// export const getOwnRecommendations = (opts) => asyncStateFunction(
//   'booksState',
//   RECOMMEND_BOOK,
//   RECOMMEND_BOOK_SUCCESS,
//   RECOMMEND_BOOK_ERROR,
//   RecommendationsService.recommendBook,
//   opts);