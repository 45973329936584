export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';
export const HIDE_SUCCESS_MESSAGE = 'HIDE_SUCCESS_MESSAGE';

export function showSuccessMessage(message) {
  return dispatch => dispatch({ type: SHOW_SUCCESS_MESSAGE, message });
}

export function hideSuccessMessage() {
  return dispatch => dispatch({ type: HIDE_SUCCESS_MESSAGE });
}
