import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyCkALRqkm4c4YgIZE7ItoTgOKbPA2jaZaQ",
  // authDomain: "pmlib-webapp.firebaseapp.com",
  authDomain: "app.thepmlibrary.com",
  databaseURL: "https://pmlib-webapp.firebaseio.com",
  projectId: "pmlib-webapp",
  storageBucket: "pmlib-webapp.appspot.com",
  messagingSenderId: "874531716216",
  appId: "1:874531716216:web:b3ddb87bbcc5e60c92418d",
  measurementId: "G-NS6GZ0VEDR" // Correct measurement ID (deploy live with this one)
  // measurementId: "G-NS6GZ0VEDR_123" // Incorrect measurement ID (keep this one active except for deployments)
};

const Firebase = firebase.initializeApp(firebaseConfig);
const GoogleProvider = new firebase.auth.GoogleAuthProvider();

const Analytics = Firebase.analytics();

Firebase.auth().onAuthStateChanged(async user => {
  user && Analytics.setUserId(user.uid);
});

export { Analytics, Firebase, GoogleProvider };
