import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BookTile from '../../components/BookTile/BookTile';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';

import ConnectedComponent from '../ConnectedComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  notesTitle: {         
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',      
      minWidth: 250,
    },
  },
  paper: {      
    margin: 'auto',    
    marginTop: 24,        
    marginBottom: theme.spacing(2),  
    minWidth: 250,
  },
  recommendationsList: {                                                  
      width: '100%',     
      padding: theme.spacing(0),                
  },    
  row: {                         
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {      
      marginBottom: theme.spacing(1),
    },                                        
  },
  recommendationsBookTile: {                                                      
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 'auto',
    },                         
  },
  recommendationsBookNote: {         
    float: 'left',         
    height: '100%',                              
    border: '3px solid #f6f6f6',
    borderRadius: '0px 8px 8px 0px',
    padding: "24px",
    minHeight: 459, 
    position: 'relative',       
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 8,
      paddingRight: 8,  
      marginLeft: 0,
      minHeight: 0,           
      border: '0px solid rgba(0, 0, 0, 0.12)',             
    },
  },
  recommendationText: {                          
    fontSize: '1.2em',        
    letterSpacing: '0.5px!important',
    lineHeight: 1.6,
    msWordBreak: 'break-word',
    wordBreak: 'break-word',
    [theme.breakpoints.down('xs')]: {
      width: 330,        
      margin: '-24px auto 6px',
      zIndex: 1,                
      padding: '16px!important',
      minHeight: 0,    
      borderRadius: '0px 0px 8px 8px',     
      border: '3px solid #f6f6f6',       
    },          
  },      
  buttonGroup: {    
    width: '100%', 
    display: 'initial',    
    textAlign: 'right',  
    position: 'absolute',
    bottom: 8,
    right: 8,  
    [theme.breakpoints.down('xs')]: {
      width: 330, 
      position: 'relative', 
      marginTop: 8,   
      right: 0,      
      margin: '-24px auto 6px',
    },
  },
  button: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  dialogActionsLeft: {
    justifyContent: "flex-start", 
  }, 
  header: {            
    marginBottom: 12,        
    fontWeight: 700,
  }, 
  description: {
    marginBottom: 12,
  },
  information: {                
    padding: '16px 24px 8px 24px!important',
    margin: '24px auto 16px',       
    backgroundColor: '#EFEFEF',
    borderRadius: 8,   
    [theme.breakpoints.down('xs')]: {
      width: '100%',     
    },  
  }, 
}));


function UserRecommendedBooks ({ authState, profileState, books, loading, profile, deleteRecommendation }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedBook, setSelectedBook] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const openDeleteDialog = bookId => () => {
    setSelectedBook(bookId);
    setOpen(true);
  }
  
  const handleDeleteRecommendation = () => {
    deleteRecommendation(selectedBook);
    setOpen(false);
  };

  const self = profileState.profile;
  const loggedin = authState.loggedin;

  // Define profile self or profile other
  let profileSelf = false;

  if (loggedin) {
    if (self.username.toLowerCase() === profile.username.toLowerCase()) {
      profileSelf = true;
    } else {
      profileSelf = false;
    }
  }

  // If recommendations exist
  let recommendationsExist = false;

  if (Array.isArray(books) && books.length) {
    recommendationsExist = true;
  };

  return (
      <div className={classes.root}>
        {loading ? 
          <></>
        :
          <>
            {recommendationsExist ?              
                <Typography className={classes.notesTitle} variant="h6" component="div" >
                  {profileSelf ?
                    <>My recommended books</>
                    :
                    <>Recommended books</>
                  }
                </Typography>
              :              
              <CardContent className={classes.information} >                                                                                                                                
                <Typography variant="h6" component="h4" className={classes.header} >
                  No book recommendations yet
                </Typography> 
                <Typography variant="body1" component="p" className={classes.description} >
                  {profileSelf ?
                    <>Discover your favourite books and recommend them to the community. <Link href="/">Discover</Link></>
                    :
                    <>{profile.name} doesn't have any book recommendations yet.</>
                  }
                </Typography>                       
              </CardContent>                
            }
          </>
        } 
        <div>
          <Grid className={classes.paper}>
            {books.map((book, i) => (
              <Grid container className={classes.row} key={i}>                                   
                <Grid item className={classes.recommendationsBookTile}>

                  <BookTile book={book} profile={profile} key={i} profileSelf={profileSelf} self={self} loggedin={loggedin} page={"profile"}></BookTile>                          

                </Grid>
                <Grid item xs={12} sm container className={classes.recommendationsBookNote}>                                                   
                  <Typography variant="body1" component="div" className={classes.recommendationText}>
                    {book.recommendations.find(r => r.user.username.toLowerCase() === profile.username.toLowerCase()).text}
                  </Typography>  

                    {profileSelf && (
                      <>
                        <ButtonGroup disableElevation size="small" className={classes.buttonGroup}>                          
                          <Button onClick={openDeleteDialog(book.id)}>Delete</Button>                                    
                        </ButtonGroup>                         
                      </>
                    )}

                </Grid>              
              </Grid>
            ))}
          </Grid>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle> 

              {/* TODO Delete the entry if the user says yes */}
              <DialogActions className={classes.dialogActionsLeft}>
                <Button 
                  size="middle" 
                  variant="outlined" 
                  color="default" 
                  disableElevation
                  startIcon={<CheckRoundedIcon />}  
                  onClick={handleDeleteRecommendation}
                >
                  Yes
                </Button> 

                <Button 
                  size="middle" 
                  variant="outlined" 
                  color="default" 
                  disableElevation
                  startIcon={<ClearRoundedIcon />}
                  onClick={handleClose}  
                >
                  No                
                </Button>    
              </DialogActions>        
          </Dialog>      
        </div>        
      </div>
      
  );
}

export default ConnectedComponent(UserRecommendedBooks);