import {
  REFRESH_USER,
  LOG_IN_EMAIL,
  LOG_IN_SUCCESS,
  LOG_IN_ERROR,
  LOG_OUT,
  LOG_OUT_SUCCESS,
  LOG_OUT_ERROR,
} from './actions';

const initialState = {
  loggedin: false,
  loading: false,
  lastUpdated: 0,
  authError: '',
  newUser: false,
  user: {},
};

function authState(state = initialState, action) {
  switch (action.type) {
    case REFRESH_USER:
      return {
        ...state,
        loading: true,
      };
    case LOG_IN_EMAIL:
      return {
        ...state,
        loading: true,
      };
    case LOG_IN_SUCCESS:
      let isNewUser = false;

      try {
        isNewUser = action.user.additionalUserInfo.isNewUser;
        console.log("it's a new user")
      } catch (e) {
        isNewUser = false;
      }

      return {
        ...state,
        loading: false,
        loggedin: true,
        newUser: isNewUser,
        // newUser: action.user.additionalUserInfo.isNewUser || false,
        user: action.user,
      };
    case LOG_IN_ERROR:
      return {
        ...state,
        loading: false,
        authError: action.message,
      };
    case LOG_OUT:
      return {
        ...state,
        loading: true,
      }
    case LOG_OUT_SUCCESS:
      return {
        ...state,
        loggedin: false,
        loading: false,
        user: {},
      };
    case LOG_OUT_ERROR:
      return {
        ...state,
        loading: false,
        authError: action.message,
      };
    default:
      return state;
  }
}

export default authState;