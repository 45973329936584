import { Analytics } from './firebase';
import { AuthService } from './auth';
import { HttpService } from './http';
import { ApolloService } from './lib/apollo';
import gql from 'graphql-tag';

class BooksService {
  static async getBooks({ limit, startAt }) {
    let user;

    try {
      user = await AuthService.getUser();
    } catch (error) {
      console.error('Could not get user')
      console.error(error);
    }

    const client = await ApolloService.client(user.authToken);

    const query = gql`
      query {
        books(limit: ${limit}, startAt: "${startAt}") {
          id
          slug
          title
          subtitle
          amazon_link
          backup_link
          cover
          description
          pages

          year
          serial

          authors {
            slug
            name
          }
          recommendations {
            id
            text
            user {
              username
              name
              role
              company
              photo
            }
          }
        }
      }
    `;

    if (startAt) {
      Analytics.logEvent('next_pagination_page');
    }

    return client.query({ query });
  }

  static async getBookBySlug({ slug }) {
    let user;

    try {
      user = await AuthService.getUser();
    } catch (error) {
      console.error('Could not get user')
      console.error(error);
    }

    const client = await ApolloService.client(user.authToken);

    const query = gql`
      query {
        bookBySlug(slug: "${slug}") {
          id
          slug
          title
          subtitle
          amazon_link
          backup_link
          cover
          description
          pages

          year
          serial

          authors {
            slug
            name
          }

          recommendations {
            id
            text
            user {
              username
              name
              role
              photo
            }
          }
        }
      }
    `;

    return client.query({ query });
  }

  static async addBook(title, subtitle, author, recommendation) {
    Analytics.logEvent('add_book', { title, subtitle, author });
    return HttpService.post('addBookForm', { title, subtitle, author, recommendation });
  }

  static async getRecommendedBooks() {
    let user;

    try {
      user = await AuthService.getUser();
    } catch (error) {
      console.error('Could not get user')
      console.error(error);
    }

    const client = await ApolloService.client(user.authToken);

    const query = gql`
      query {
        recommendedBooks {
          text
          userId
          user {
            username
            name
            photo
          }
          book {
            id
            slug
            title
            subtitle
            amazon_link
            backup_link
            cover
            description
            pages

            year
            serial

            authors {
              slug
              name
            }
            recommendations {
              id
              text
              user {
                username
                name
                role
                company
                photo
              }
            }
          }
        }
      }
    `;

    return client.query({ query });
  }
}

export { BooksService };
