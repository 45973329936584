import {combineReducers} from 'redux';
import authState from './auth/reducer';
import booksState from './books/reducer';
import feedbackState from './feedback/reducer';
import messagesState from './messages/reducer';
import profileState from './profile/reducer';
import usersState from './users/reducer';
import recommendationsState from './recommendations/reducer';
import uiState from './ui/reducer';

const rootReducer = combineReducers({
  authState,
  booksState,
  feedbackState,
  messagesState,
  profileState,
  uiState,
  usersState,
  recommendationsState,
});

export default rootReducer;
