import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import ConnectedComponent from '../../components/ConnectedComponent';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';

const useStyles = makeStyles((theme) => ({
  information: {
    padding: '16px 24px 24px 24px!important',
    margin: '24px auto 16px',
    backgroundColor: '#f6f6f6',
    color: "#111",
    textAlign: "center",
    borderRadius: 8,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  header: {
    marginBottom: 12,
    marginTop: 8,
    fontWeight: 500,
    width: "50%",
    margin: "auto",
    lineheight: 1.4,
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
}));

function UserLoggedOutBanner({loading, logInGoogle, authState}) {
  const classes = useStyles();
  const [loggedin, setLoggedin] = useState(authState.loggedin);

  useEffect(() => {
    if (authState.loggedin && !loggedin) {
      window.location.reload();
    }
  }, [authState.loggedin]);

  return (
    <div className={classes.root}>
      {!loggedin ? <>
      {loading ? (
          <Skeleton variant="text" />
        ) : (
          <div>
            <CardContent className={classes.information} >
              <Typography variant="h6" component="h4" className={classes.header} >
                Discover the favourite books of more than 300 people from the tech and startup industry.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={logInGoogle}
                startIcon={<PersonAddRoundedIcon />}
              >
                Join free with Google
              </Button>
            </CardContent>
          </div>
        )}
      </>:<></>}
    </div>

  );
};

export default ConnectedComponent(UserLoggedOutBanner);
