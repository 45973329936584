import { ProfileService } from '../../services/profile';
import { asyncStateFunction } from '../util';

export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PROFILE_SUCCESS = 'SET_PROFILE_SUCCESS';
export const SET_PROFILE_ERROR = 'SET_PROFILE_ERROR';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_USERNAME_SUCCESS = 'SET_USERNAME_SUCCESS';
export const SET_USERNAME_ERROR = 'SET_USERNAME_ERROR';


export const getProfile = (opts) => asyncStateFunction(
  'profileState',
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  ProfileService.getProfile);

export const setProfile = (opts) => asyncStateFunction(
  'profileState',
  SET_PROFILE,
  SET_PROFILE_SUCCESS,
  SET_PROFILE_ERROR,
  ProfileService.setProfile,
  opts,
  false);

export const setUsername = (opts) => asyncStateFunction(
  'profileState',
  SET_USERNAME,
  SET_USERNAME_SUCCESS,
  SET_USERNAME_ERROR,
  ProfileService.setUsername,
  opts,
  false);

export const resetState = () => dispatch => dispatch({ type: 'RESET' });