import {
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
} from './actions';

const initialState = {
  successMessageOpen: false,
  message: '',
};

function messagesState(state = initialState, action) {
  switch (action.type) {
    case SHOW_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessageOpen: true,
        message: action.message,
      };
    case HIDE_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessageOpen: false,
        message: '',
      };
    default:
      return state;
  }
}

export default messagesState;
