import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BookTile from '../BookTile/BookTile';
import { useInView } from 'react-intersection-observer';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ConnectedComponent from '../ConnectedComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '68px',
  },
  title: {
    margin: '0px 24px 18px 0px',
    paddingTop: 0,
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      margin: '0px 24px 18px 24px',
      fontSize: '1.625rem',
    },
  },
  subtitle: {
    margin: '0px 24px 24px 0px',
    paddingTop: 0,
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.54)',
    width: '65%',
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      margin: '0px 24px 24px 24px',
      fontSize: '1.225rem',
      width: 'auto',
    },
  },

}));

function BookList({ booksState, booksLoading, getPage }) {
  const classes = useStyles();

  const matches = useMediaQuery('(min-width:600px)');


  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: '800px',
  });

  const books = booksState.books;

  if (inView) {
    getPage({limit: 40, startAt: booksState.cursorSlug});
  }

  return (
    <div className={classes.root} >
      <Typography gutterBottom variant="h4" component="h1" className={classes.title} >
        All books
      </Typography>
      <Typography variant="h5" component="h2" className={classes.subtitle} >
        Check out all available books in the library or search for a specific book using the search field in the header.
      </Typography>

      <Grid
        container
        spacing={2}
        nowrap={'nowrap'}
        direction="row"
        justify={matches ? ("flex-start") : ("center") }
        alignItems="flex-start">
        {books.map((book, i) => (
          book.authors.length > 0 && (
            book.cover.length > 0 && (
              <BookTile book={book} key={i} page={"list"}></BookTile>
            )
          )
        ))}
      </Grid>

      <div ref={ref} /> {/* This is the trigger for pagination */}

      {/* <Button onClick={loadMore}>Load more</Button> */}

      <div style={booksLoading ? {width: '100%', textAlign: 'center', padding: 20} : { display: 'none' }}>
        <CircularProgress ></CircularProgress>
      </div>
    </div>
  );
}

export default ConnectedComponent(BookList);
