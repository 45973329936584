import React from 'react';

const imgStyles = {
  borderRadius: 9999,
  backgroundColor: '#999',
};

export function Avatar({ alt, src, size, style }) {
  return (    
    <img src={src} alt={alt} style={{...style, ...imgStyles, height: size, width: size}} referrerPolicy={'no-referrer'} />
  );
}
