import React, { useState, useEffect } from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import Oops from './Oops';
import Loading from './Loading';

import Login from './loggedout/Login';
import SecretLogin from './loggedout/SecretLogin';
import PublicProfile from './loggedout/PublicProfile';

import Main from './loggedin/Main';
import NewUser from './loggedin/NewUser';
import Profile from './loggedin/Profile';
import AddBook from './loggedin/AddBook';
import ViewBook from './loggedin/ViewBook';
import UserList from './loggedin/UserList';

import { refreshUser } from '../state/auth/actions';
import { getProfile } from '../state/profile/actions';
import { getPage } from '../state/books/actions';
import logoBig from '../assets/logo-pm-library_s.png';

const spinnerStyle = {
  width: 48,
  height: 48,
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -24,
  marginLeft: -24,
  display: 'none',
}

const logoStyle = {
  height: 68,
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: '-34px',
  marginLeft: '-105px',
}

function AppComponent({ authState, refreshUser, getProfile, getPage, history }) {
  useEffect(() => {
    refreshUser();
    getProfile();
  }, []);

  useEffect(() => {
    if (authState.loggedin) {
      getProfile();
    }
  }, [authState.loggedin]);

  useEffect(() => {
    if (authState.newUser) {
      window.location.assign('/newuser');
    }
  }, [authState.newUser]);

  if (authState.loading) {
    return (
      <div style={{height: '100vh', width: '100vw'}}>
        <img alt="PM Library Logo" src={logoBig} style={logoStyle} />
        <CircularProgress style={spinnerStyle}></CircularProgress>
      </div>
    );
  } else {
    if (authState.loggedin) {
      return (
        <Switch>
          <Route exact path='/' component={Main} />
          <Route exact path='/newuser' component={NewUser} />
          <Route exact path='/profile' component={Profile} />
          <Route exact path='/addbook' component={AddBook} />
          {/* <Route exact path='/design' component={DesignReview} /> */}
          <Route exact path='/u/:username' component={PublicProfile} />
          <Route exact path='/b/:bookId' component={ViewBook} />
          <Route exact path='/users' component={UserList} />
          <Route component={() => <Redirect to="/" />} />
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route exact path='/' component={Login} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/pmlstaff-x7hf409f' component={SecretLogin} />
          <Route exact path='/u/:username' component={PublicProfile} />
          <Route exact path='/newuser' component={Loading} />
          <Route exact path='/b/:bookId' component={Loading} /> {/* This is just here to avoid hitting the catch-all while logged in */}
          <Route exact path='/profile' component={Loading} /> {/* This is just here to avoid hitting the catch-all while logged in */}
          <Route exact path='/addbook' component={Loading} /> {/* This is just here to avoid hitting the catch-all while logged in */}
          <Route exact path='/users' component={Loading} /> {/* This is just here to avoid hitting the catch-all while logged in */}
          <Route component={() => <Redirect to="/" />} />
        </Switch>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  authState: state.authState
});

const mapDispatchToProps = (dispatch) => ({
  refreshUser: (firstAuthCallback) => dispatch(refreshUser(firstAuthCallback)),
  getProfile: () => dispatch(getProfile()),
  getPage: opts => dispatch(getPage(opts)),
});

const App = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AppComponent));

export { App };
