import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import ConnectedComponent from '../../components/ConnectedComponent';

import Header from '../../components/Header/Header';
import AddBookForm from '../../components/AddBookForm/AddBookForm';
import Messages from '../../components/Messages/Messages';

function AddBook({ showSuccessMessage, addBook }) {
  return (
    <div>
      <Header></Header>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="md">
          <AddBookForm handleSave={addBook} showSuccessMessage={showSuccessMessage}></AddBookForm>
        </Container>
        <Messages></Messages>
      </React.Fragment>
    </div>
  );
}

export default ConnectedComponent(AddBook);
