import { AuthService } from '../../services/auth';

export const REFRESH_USER = 'REFRESH_USER';
export const SIGN_UP_EMAIL = 'SIGN_UP_EMAIL';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';
export const LOG_IN_EMAIL = 'LOG_IN_EMAIL';
export const LOG_IN_GOOGLE = 'LOG_IN_GOOGLE';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_ERROR = 'LOG_IN_ERROR';
export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_ERROR = 'LOG_OUT_ERROR';

export function refreshUser() {
  return (dispatch, getState) => {
    if (getState().authState.loading) {
      return Promise.resolve();
    }

    dispatch({
      type: REFRESH_USER,
    });

    AuthService.getUser()
        .then((user) => {
          dispatch({
            type: LOG_IN_SUCCESS,
            user,
          });
        })
        .catch(error => {
          // console.log('ERROR!', error)
          dispatch({
            type: LOG_IN_ERROR,
            message: error,
          });
        });
  }
}

export function logInEmail(email, password) {
  return (dispatch, getState) => {
    if (getState().authState.loading) {
      return Promise.resolve();
    }

    dispatch({
      type: LOG_IN_EMAIL,
    });

    AuthService.getUser()
        .then((user) => {
          console.log('Already logged in', user);
          dispatch({
            type: LOG_IN_SUCCESS,
            user,
          });
        })
        .catch(() => {
          AuthService.userLogin(email, password)
              .then(user => {
                console.log('Success logging in', user);
                dispatch({
                  type: LOG_IN_SUCCESS,
                  user,
                });
              })
              .catch(error => {
                console.log('error logging in', error);
                dispatch({
                  type: LOG_IN_ERROR,
                  message: error,
                });
              });
        });
  };
}

export function logInGoogle() {
  return (dispatch, getState) => {
    if (getState().authState.loading) {
      return Promise.resolve();
    }

    dispatch({
      type: LOG_IN_GOOGLE,
    });

    AuthService.getUser()
        .then((user) => {
          console.log('Already logged in', user);
          dispatch({
            type: LOG_IN_SUCCESS,
            user,
          });
        })
        .catch(() => {
          AuthService.userLoginGoogle()
              .then(user => {
                console.log('Success logging in', user);
                dispatch({
                  type: LOG_IN_SUCCESS,
                  user,
                });
              })
              .catch(error => {
                console.log('error logging in', error);
                dispatch({
                  type: LOG_IN_ERROR,
                  message: error,
                });
              });
        });
  };
}

export function signUpEmail(email, password) {
  return (dispatch, getState) => {
    if (getState().authState.loading) {
      return Promise.resolve();
    }

    dispatch({
      type: SIGN_UP_EMAIL,
    });

    AuthService.userSignup(email, password)
        .then(user => {
          dispatch({
            type: SIGN_UP_SUCCESS,
            user,
          });
        })
        .catch(error => {
          console.log('error signing up', error);
          dispatch({
            type: SIGN_UP_ERROR,
            message: error,
          });
        });
  };
}

export function logOut() {
  return (dispatch, getState) => {
    if (getState().authState.loading) {
      return Promise.resolve();
    }

    dispatch({
      type: LOG_OUT,
    });

    AuthService.signOut()
        .then(() => {
          dispatch({
            type: LOG_OUT_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch({
            type: LOG_OUT_ERROR,
            message: error,
          });
        });
  };
}