import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import UserTile from '../UserTile/UserTile';
import Typography from '@material-ui/core/Typography';

import ConnectedComponent from '../ConnectedComponent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '82px',
  },
  title: {
    margin: '0px 24px 18px 0px',
    paddingTop: '24px',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      margin: '0px 24px 18px 24px',
      fontSize: '1.625rem',
    },
  },
  subtitle: {
    margin: '0px 24px 18px 0px',
    paddingTop: 0,
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.54)',
    width: '65%',
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      margin: '0px 24px 18px 24px',
      fontSize: '1.225rem',
      width: 'auto',
    },
  },
  viewAllUser: {
    margin: '0px 0px 24px',
    [theme.breakpoints.down('xs')]: {
      width: '60%',
      marginLeft: '20%',
    },
  },
}));

function shuffleArray(arr) {
  if (arr.length === 0) {
    return []
  }

  const a = arr.slice()
  const result = []

  for (let i = 1; i < arr.length; i++) {
    result.push(a.splice(Math.floor(Math.random()*a.length), 1).pop())
  }

  return result
}

function filterGoodProfiles(arr) {
  return arr.filter(user => user.name && user.role && user.company && user.company && user.recommendations.length > 0)
}

function getWidth() {
  // return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  return width > 1248 ? 1248 : width
}

function BookList({ userList, usersState, history, booksLoading }) {
  const classes = useStyles();
  const [width, setWidth] = useState(getWidth())
  const [shuffledUsers, setShuffledUsers] = useState([])

  const matches = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    userList()
  }, [])

  useEffect(() => {
    // console.log('users', usersState.users)

    setShuffledUsers(shuffleArray(filterGoodProfiles(usersState.users)))
  }, [usersState.users])

  useEffect(() => {
    const resizeListener = () => {
      setWidth(getWidth())
    };

    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  const numCards = 1 * Math.floor((width - 128) / 250) // 250 is the width of the card
                                                       // 128 is the padding buffer
                                                       // 1 is the number of rows

  return (
    <div className={classes.root} >
      <Typography gutterBottom variant="h4" component="h1" className={classes.title} >
        On my shelf
      </Typography>
      <Typography variant="h5" component="h2" className={classes.subtitle} >
        Get inspired by book recommendations from real people.
      </Typography>

      <Button
        className={classes.viewAllUser}
        size="small"
        variant="outlined"
        color="default"
        disableElevation
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={() => history.push(`/users`)}
        startIcon={<PeopleRoundedIcon />}
      >
        View all users
      </Button>

      <Grid
        container
        spacing={2}
        nowrap={'nowrap'}
        direction="row"
        justify={matches ? ("flex-start") : ("center") }
        alignItems="flex-start">
        {shuffledUsers.slice(0, numCards).map((user, i) =>
            <UserTile user={user} key={i} />)
        }
      </Grid>

      <div style={booksLoading ? {width: '100%', textAlign: 'center', padding: 20} : { display: 'none' }}>
        <CircularProgress ></CircularProgress>
      </div>
    </div>
  );
}

export default ConnectedComponent(BookList);
