import {
  SHOW_NO_RESULTS_MESSAGE,
} from './actions';

const initialState = {
  noResultsMessageOpen: true,
};

function uiState(state = initialState, action) {
  switch (action.type) {
    case SHOW_NO_RESULTS_MESSAGE:
      return {
        ...state,
        noResultsMessageOpen: true,
      };
    default:
      return state;
  }
}

export default uiState;
