import { asyncStateFunction } from '../util';
import { BooksService } from '../../services/books';

export const GET_RECOMMENDED_BOOKS = 'GET_RECOMMENDED_BOOKS';
export const GET_RECOMMENDED_BOOKS_SUCCESS = 'GET_RECOMMENDED_BOOKS_SUCCESS';
export const GET_RECOMMENDED_BOOKS_ERROR = 'GET_RECOMMENDED_BOOKS_ERROR';

export const getRecommendedBooks = (opts) => asyncStateFunction(
  'recommendationsState',
  GET_RECOMMENDED_BOOKS,
  GET_RECOMMENDED_BOOKS_SUCCESS,
  GET_RECOMMENDED_BOOKS_ERROR,
  BooksService.getRecommendedBooks,
  opts);
