import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { SearchBox } from '../SearchBox/SearchBox';
import { FeedbackDialog } from '../Feedback/FeedbackDialog';

import AppBar from '@material-ui/core/AppBar';
import { Avatar } from '../Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MenuRounded';

// Drawer
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';

import PeopleIcon from '@material-ui/icons/PeopleRounded';
import MenuBookIcon from '@material-ui/icons/MenuBookRounded';
import BookIcon from '@material-ui/icons/BookRounded';
import LocalPostOfficeIcon from '@material-ui/icons/LocalPostOfficeRounded';
import RSSFeedIcon from '@material-ui/icons/RssFeedRounded';
import ListAltIcon from '@material-ui/icons/ListAltRounded';
import CameraAltIcon from '@material-ui/icons/CameraAltRounded';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenterRounded';
import LocalLibraryIcon from '@material-ui/icons/LocalLibraryRounded';
import ContactsIcon from '@material-ui/icons/ContactsRounded';
import FacebookIcon from '@material-ui/icons/Facebook';
import FeedbackIcon from '@material-ui/icons/FeedbackRounded';
import MessageIcon from '@material-ui/icons/MessageRounded';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import AttachmentRoundedIcon from '@material-ui/icons/AttachmentRounded';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';

import ConnectedComponent from '../ConnectedComponent';

import logoBig from '../../assets/logo-pm-library_s.png';
import logoSmall from '../../assets/logo-pm-library_small_s.png';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  header:{
    boxShadow: '0 3px 4px rgba(0,0,0,.04), 0 1px 1px rgba(1,0,0,.1)',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  button: {
    '& > *': {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(0.25),
      marginBottom: theme.spacing(0.25),
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  iconButtonProfileImage: {
    padding: 0,    
    [theme.breakpoints.up('md')]: {
      top: 2,
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: '#F6F6F6',
    fontSize: 12,
    color: '#292A2C',
    padding: '0px!important',    
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  multipleButtons: {
    '& > *': {
      marginRight: theme.spacing(2),
      top: 2,
    },
  },
  logoDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    height: '50px',
  },
  logoMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    height: '50px',
  },
  menuPopup: {
    '& .MuiMenu-paper': {
      top: "40px!important",
    },
  },
  hamburgerItem: {
    marginLeft: '12px',
  },
  hamburger: {
    padding: '6px',
    margin: '2px',
    backgroundColor: '#F6F6F6',
  },
}));

function Header({ logout, authState, profileState, uiState, history }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [profile, setProfile] = useState(profileState.profile);
  const [loggedin, setLoggedin] = useState(authState.loggedin);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setProfile(profileState.profile);
    setLoggedin(authState.loggedin);
  }, [profileState.profile, authState.loggedin])

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.menuPopup}
    >

      <MenuItem onClick={() => {
        handleMenuClose();
        history.push(`/u/${profile.username}`);
        }}>View Profile
      </MenuItem>

      <MenuItem onClick={() => {
        handleMenuClose();
        history.push('/profile');
        }}>Edit Profile
      </MenuItem>

      <MenuItem onClick={() => {
        handleMenuClose();
        logout();
        history.push('/');
        }}>Logout
      </MenuItem>

    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className={classes.menuPopup}
    >

      <MenuItem onClick={() => {
        handleMenuClose();
        history.push('/addbook');
        }}>Suggest a book
      </MenuItem>

      <MenuItem onClick={() => {
        handleMenuClose();
        history.push(`/u/${profile.username}`);
        }}>View Profile
      </MenuItem>

      <MenuItem onClick={() => {
        handleMenuClose();
        history.push('/profile');
        }}>Edit Profile
      </MenuItem>

      <MenuItem onClick={() => {
        handleMenuClose();
        logout();
        history.push('/');
        }}>Logout
      </MenuItem>

    </Menu>
  );

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {/* <ListItem button onClick={() => history.push('/')}>
          <ListItemIcon><MenuBookIcon /></ListItemIcon>
          <ListItemText primary='All Books' />
        </ListItem> */}
        {/* <ListItem button component="a" target="_blank" href='https://thepmlibrary.com/collections/' >
          <ListItemIcon><LocalLibraryIcon /></ListItemIcon>
          <ListItemText primary='Book Collections' />
        </ListItem>
        <ListItem button component="a" target="_blank" href='https://thepmlibrary.com/authors/' >
          <ListItemIcon><PeopleIcon /></ListItemIcon>
          <ListItemText primary='All Authors' />
        </ListItem> */}
      </List>
      {/* <Divider /> */}
      {/* <List>
        <ListItem button component="a" target="_blank" href='https://medium.com/the-pm-library' >
          <ListItemIcon><RSSFeedIcon /></ListItemIcon>
          <ListItemText primary='Our Blog' />
        </ListItem>
        <ListItem button component="a" target="_blank" href='https://medium.com/the-pm-library/book-sips/home' >
          <ListItemIcon><BookIcon /></ListItemIcon>
          <ListItemText primary='Book Sips' />
        </ListItem>
        <ListItem button component="a" target="_blank" href='https://medium.com/the-pm-library/on-my-shelf/home' >
          <ListItemIcon><ListAltIcon /></ListItemIcon>
          <ListItemText primary='On my Shelf' />
        </ListItem>
      </List> */}
      {/* <Divider /> */}
      <List>
        <ListItem button component="a" target="_blank" href='https://pmlibrary.eo.page/hzc7j' >
          <ListItemIcon><LocalPostOfficeIcon /></ListItemIcon>
          <ListItemText primary='Newsletter' />
        </ListItem>
        <ListItem button component="a" target="_blank" href='https://www.linkedin.com/company/pmlibrary/' >
          <ListItemIcon><BusinessCenterIcon /></ListItemIcon>
          <ListItemText primary='LinkedIn' />
        </ListItem>
        <ListItem button component="a" target="_blank" href='https://twitter.com/thepmlibrary' >
          <ListItemIcon><MessageIcon /></ListItemIcon>
          <ListItemText primary='Twitter' />
        </ListItem>
        <ListItem button component="a" target="_blank" href='https://www.instagram.com/thepmlibrary/' >
          <ListItemIcon><CameraAltIcon /></ListItemIcon>
          <ListItemText primary='Instagram' />
        </ListItem>
        <ListItem button component="a" target="_blank" href='https://www.facebook.com/thepmlibrary' >
          <ListItemIcon><FacebookIcon /></ListItemIcon>
          <ListItemText primary='Facebook' />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button component="a" target="_blank" href='mailto:alex@thepmlibrary.com' >
          <ListItemIcon><FeedbackIcon /></ListItemIcon>
          <ListItemText primary='Give feedback' />
        </ListItem>
        <ListItem button component="a" target="_blank" href='https://medium.com/the-pm-library/our-people-behind-the-pm-library-c4c007807ba4' >
          <ListItemIcon><ContactsIcon /></ListItemIcon>
          <ListItemText primary='About us' />
        </ListItem>
        <ListItem button component="a" target="_blank" href='https://thepmlibrary.com/terms/' >
          <ListItemIcon><AttachmentRoundedIcon /></ListItemIcon>
          <ListItemText primary='Privacy & Terms' />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" color="inherit" className={classes.header}>
        <Toolbar>
          <Link to="/">
            <img alt="PM Library Logo" src={logoBig} className={classes.logoDesktop} />
            <img alt="PM Library Logo" src={logoSmall} className={classes.logoMobile} />
          </Link>

          {loggedin ?
            <SearchBox history={history}></SearchBox>
            : <></>
          }

          <div className={classes.grow} />

          <div className={classes.sectionDesktop}>
            {!loggedin ? <>
              <div className={classes.button}>
                <Button
                  color="default"
                  size="middle"
                  disableElevation
                  onClick={() => history.push('/')}
                  style={{marginLeft: '0px', marginRight: '8px',}}
                >
                  Login
                </Button>
              </div>
              <div className={classes.button}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="middle"
                  disableElevation
                  startIcon={<PersonAddRoundedIcon />}
                  onClick={() => history.push('/')}
                  style={{marginLeft: '8px'}}
                >
                  Join free
                </Button>
              </div>

              <Divider orientation="vertical" flexItem />
            </>:<></>}

            <div className={classes.button}>
              <Button
                variant={(loggedin) ? 'contained' : 'outlined'}
                startIcon={(loggedin) ? <AddRoundedIcon /> : <></>}
                color={(loggedin) ? 'secondary' : 'default'}
                size="middle"
                disableElevation
                onClick={() => history.push((loggedin) ? '/addbook' : '/')}
              >
                Suggest a book
              </Button>


            </div>
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                className={classes.iconButtonProfileImage}
              >
                { profile.photo ?
                  <Avatar
                    alt={profile.name}
                    src={profile.photo}
                    size={36}
                    style={{objectFit: 'cover'}}
                    className={classes.small}
                  />
                  : <></>
                }
              </IconButton>
            </div>
            <div className={classes.hamburgerItem}>
              {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={toggleDrawer(anchor, true)}
                    color="inherit"
                    className={classes.hamburger}
                  >
                    <MoreIcon />
                  </IconButton>
                  <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </div>
          </div>

          <div className={classes.sectionMobile}>
          {!loggedin ? <>
            <div className={classes.button}>
              <Button
                variant="contained"
                color="secondary"
                size="middle"
                disableElevation
                startIcon={<PersonAddRoundedIcon />}
                onClick={() => history.push('/')}
                style={{marginLeft: '8px'}}
              >
                Join free
              </Button>
            </div>
          </>:<></>}

          {loggedin ?
            <IconButton
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup={true}
            disableFocusRipple={true}
            onClick={handleMobileMenuOpen}
            color="inherit"
            className={classes.iconButtonProfileImage}
            >
              <Avatar
                src={profile.photo}
                size={36}
                style={{objectFit: 'cover'}}
                className={classes.small}
              />
            </IconButton>
            : <></>
          }
            <div className={classes.hamburgerItem}>
              {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={toggleDrawer(anchor, true)}
                    color="inherit"
                    className={classes.hamburger}
                  >
                    <MoreIcon />
                  </IconButton>
                  <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}

      <FeedbackDialog loggedin={loggedin} />
    </div>
  );
}

export default ConnectedComponent(Header);

