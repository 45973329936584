import React from 'react';

function CommaSeparatedListAuthors({ authors }) {
  return (
    <span>
      {authors.map(a => a.name).join(', ')}
    </span>
  );
}

export default CommaSeparatedListAuthors;