import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import ConnectedComponent from '../../components/ConnectedComponent';

import Header from '../../components/Header/Header';
import BookList from '../../components/BookList/BookList';
import RecosBookList from '../../components/RecosBookList/RecosBookList';
import UserList from '../../components/UserList/UserList';
import Messages from '../../components/Messages/Messages';

function Main({ booksState }) {
  return (
    <div>
      <Header></Header>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth={'lg'}>
          <UserList booksLoading={booksState.loading} ></UserList>
          <RecosBookList booksLoading={booksState.loading} ></RecosBookList>
          <BookList booksLoading={booksState.loading} ></BookList>
        </Container>
        <Messages></Messages>
      </React.Fragment>
    </div>
  );
}

export default ConnectedComponent(Main);
