import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import logoBig from '../../assets/logo-pm-library_s.png';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import ConnectedComponent from '../../components/ConnectedComponent';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://thepmlibrary.com/books/">
        PM Library
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const root = {
  height: '100vh',
}
const image = {
  backgroundImage: "url('/1920.png')",
  backgroundRepeat: 'no-repeat',
  backgroundColor: 'grey',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}
const paper = {
    
  display: 'flex',
  boxSizing: 'box-border',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',    
}
const form = {
  width: '80%%', // Fix IE 11 issue.
  marginTop: 32,
}
const submit = {
  margin: "6px 0px",   
}
const logoDesktop = {  
  height: '60px',
  margin: "24px 0px 96px",
}
const intro = {  
  width: 300,
  marginTop: 12,
}

function Login({ signUpEmail, logInEmail }) {
  const [lEmail, setLEmail] = useState('');
  const [lPassword, setLPassword] = useState('');
  const [sEmail, setSEmail] = useState('');
  const [sPassword, setSPassword] = useState('');

  const handleSignup = () => {
    signUpEmail(sEmail, sPassword);
    logInEmail(sEmail, sPassword);
  };

  const handleLogin = () => {
    logInEmail(lEmail, lPassword);
  }

  return (
    <div>
      <Grid container component="main" style={root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} style={image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div style={paper}>                                        
            <Box >
              <img alt="PM Library Logo" src={logoBig} style={logoDesktop} />                           
            </Box>
            <Typography component="h1" variant="h4" gutterBottom>
              Join the first social<br/>book library!
            </Typography>
            <Typography component="p" variant="body1" align="center" display="block" style={intro}>
              Reading is a fantastic way to learn. Books can take you into areas and places you’ve never been before, they can teach you from failures and successes, and they can tell you thrilling stories about people, products, and teams.
            </Typography>
            <form style={form} noValidate>
              Sign up
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={e => setSEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={e => setSPassword(e.target.value)}
              /> 
              <Button 
                variant="contained" 
                color="primary" 
                style={submit}                  
                onClick={handleSignup}  
                fullWidth
                startIcon={<PersonAddRoundedIcon />}      
              >                  
                Sign up
              </Button>

              Log in
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={e => setLEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={e => setLPassword(e.target.value)}
              /> 
              <Button 
                variant="contained" 
                color="primary" 
                style={submit}                  
                onClick={handleLogin}  
                fullWidth
                startIcon={<PersonAddRoundedIcon />}      
              >                  
                Log in
              </Button>

              <Grid container>
                {/* <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid> */}
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
  
export default ConnectedComponent(Login);
