import ApolloClient, { InMemoryCache } from 'apollo-boost';

async function client(token) {
  const opts = {
    cache: new InMemoryCache(),
    // uri: 'http://localhost:4000/',
    uri: 'https://api.thepmlibrary.com',
  }

  if (token) {
    opts.headers = { token };
  }

  return new ApolloClient(opts);
}

const ApolloService = { client };

export { ApolloService };