import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import PopupBookDetails from '../BookDetail/PopupBookDetails';
import RecommendationsAvatarGroup from '../UserInteraction/RecommendationsAvatarGroup';
import RecommendationsNoteDialog from '../UserInteraction/RecommendationsNoteDialog';
import CommaSeparatedListAuthors from '../Elements/CommaSeparatedListAuthors';
import PurchaseButton from '../UserInteraction/PurchaseButton';

import ConnectedComponent from '../ConnectedComponent';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 260,
        height: 459,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
        border: '3px solid #F6F6F6',
        [theme.breakpoints.down('xs')]: {
          margin: 'auto',   
          height: 'auto',  
          maxWidth: '100%',
        },
    },
    root_profile: {
      maxWidth: 330,
      height: 459,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '8px 0px 0px 8px',
      borderRight: 'none',
      border: '3px solid #F6F6F6',
      [theme.breakpoints.down('xs')]: {
        margin: 'auto',           
        height: 'auto',   
        borderRight: '3px solid #F6F6F6',
        borderBottom: 'none',
        borderRadius: '8px 8px 0px 0px',
        paddingBottom: 8,
      },
    },
    media: {        
        width: "100%",
        backgroundColor: '#F6F6F6',
        paddingTop: '24px',
        paddingBottom: '26px',
    },
    media_profile: {        
        width: 330,
        backgroundColor: '#F6F6F6',
        paddingTop: '24px',
        paddingBottom: '26px',
    },
    img: {
        height: '150px',                
        objectFit: 'contain',
        width: 'initial',
        boxShadow: '0 2px 1px rgba(0,0,0,0.09), 0 4px 2px rgba(0,0,0,0.09), 0 8px 4px rgba(0,0,0,0.09), 0 16px 8px rgba(0,0,0,0.09), 0 32px 16px rgba(0,0,0,0.09)',
        margin: 'auto',
        borderRadius: 2,
    },
    actions: {
        display: 'flex',
        marginTop: 'auto',              
    },
    actionArea: {
        height: 205,
        width: 250,
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {          
          height: 'auto',          
        },
    },  
    avatarGroup: {
      marginRight: 4,
    },
    bookActions: {
        justifyContent: 'flex-end',
        paddingRight: 0,
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',       
        lineHeight: '1.25',
    }, 
    subtitle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        lineHeight: '26px',
    },
    authors: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      lineHeight: 1.4,
  }, 
    body1: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
    },
}));

function BookTile({ book, page }) {
  const classes = useStyles();
  const [detailOpen, setDetailOpen] = React.useState(false);
  const handleDetailOpen = () => setDetailOpen(true);
  const handleDetailClose = () => setDetailOpen(false);
  
  return (
    <Grid item >
      <Grid container>
        
        <Card          
          className={page === 'profile' ? classes.root_profile : classes.root}     
          variant="outlined"                        
        >
          <CardActionArea onClick={handleDetailOpen}>
            <div className={page === 'profile' ? classes.media_profile : classes.media}>
              <CardMedia
                component="img"
                alt={book.title}
                image={book.cover}
                title={book.title}
                className={classes.img}                                  
              />
            </div>               
            <CardContent className={classes.actionArea}>
              <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                {book.title}
              </Typography>
              <Typography gutterBottom variant="h6" component="h3" color="textSecondary" className={classes.subtitle}>
                {book.subtitle}
              </Typography>
              <Typography variant="body1" color="textSecondary" component="p" className={classes.authors}>
                by <CommaSeparatedListAuthors authors={book.authors} />
              </Typography>
            </CardContent>
          </CardActionArea>              

          <CardActions className={classes.bookActions}>            
            {book.recommendations ? 
              <div 
                onClick={handleDetailOpen}
                className={classes.avatarGroup}>
                <RecommendationsAvatarGroup recommendations={book.recommendations} />
              </div>
              : <></>}
            <RecommendationsNoteDialog book={book} small={'true'} />

            {/* <PurchaseButton book={book} /> */}
                                     
            <PopupBookDetails
              book={book}
              open={detailOpen}
              handleOpen={handleDetailOpen}
              handleClose={handleDetailClose} 
            ></PopupBookDetails>
          </CardActions>
          
        </Card>   
      </Grid>                                                   
    </Grid>                        
  );
}

export default ConnectedComponent(BookTile);