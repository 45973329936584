import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({  
  rootList: {
    width: '100%',    
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    minHeight: 72,
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      marginBottom: 18,
    },
  },  
  recommendationAvatar: {
    width: '5%',
    height: '100%',
    marginTop: 0,    
  },
  recommendationAuthor: {
    width: '25%',
    height: '100%',
    marginLeft: '1%',
    fontWeight: 700,
    display: 'block',    
    [theme.breakpoints.down('xs')]: {
      width: '70%',     
    },     
  },
  inline: {    
    [theme.breakpoints.down('xs')]: {
      width: '100%',     
    },     
  },
  recommendationText: {
    width: '74%',     
    height: '100%',
    marginLeft: '1%',   
    marginTop: 8,
    [theme.breakpoints.down('xs')]: {
      width: '100%',     
    },    
  }, 
  profileLink: {    
    fontWeight: 700,
    textDecoration: 'none',
    color: theme.palette.main.dark,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  }, 
}));


export default function RecommendationsList({ recommendations }) {
  // console.log('recommendations', recommendations);
  const classes = useStyles();

  return (    
    <List className={classes.rootList}>
      {recommendations.map((rec, i) =>
        <ListItem 
          alignItems="flex-start" 
          key={i} 
          disableGutters
          className={classes.listItem} >
          
          <ListItemAvatar className={classes.recommendationAvatar}>
            <Link to={`/u/${rec.user.username}`}>
              <Avatar alt={rec.user.name} src={rec.user.photo} />
            </Link>
          </ListItemAvatar>

          <ListItemText
            primary={
              <Link 
                to={`/u/${rec.user.username}`}
                className={classes.profileLink}                
              >{rec.user.name}</Link>
            }
            className={classes.recommendationAuthor}
            secondary={                    
              <Typography
                component="div"
                variant="body1"
                className={classes.inline}
                color="textPrimary"
              >
                {rec.user.role}

                {/* // TODO SHANE: add company of a user to the recommendation info */}
                {/* {rec.user.role} @ {rec.user.company} */}
              </Typography>                                        
            }              
          />

          <Typography
            component="div"
            variant="body1"
            className={classes.recommendationText}
            color="textPrimary">
              {rec.text}
          </Typography>

        </ListItem>
      )}
    </List>                                                                            

  );
}

export { RecommendationsList };