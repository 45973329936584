import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import ConnectedComponent from '../../components/ConnectedComponent';
import Header from '../../components/Header/Header';
import SingleBookDetails from '../../components/BookDetail/SingleBookDetails';

import { BooksService } from '../../services/books';

function ViewBook({ match, location }) {
  const [book, setBook] = useState({
    id: '',
    authors: [],
    cover: '',
    title: '',
    subtitle: '',
    year: '',
    pages: '',
    description: '',
    publisher: '',
    amazon_link: '',
    recommendations: [],
  });

  const [notFound, setNotFound] = useState(false);
  
  useEffect(() => {
    (async function() {
      const data = await BooksService.getBookBySlug({ slug: match.params.bookId });

      if (data.data.bookBySlug) {
        const { id, authors, cover, title, subtitle, year, pages, description, publisher, amazon_link, recommendations } = data.data.bookBySlug;
        setBook({ id, authors, cover, title, subtitle, year, pages, description, publisher, amazon_link, recommendations });
      } else {
        setNotFound(true);
      }
    })();
  }, [location]);

  return (
    <div>
      <Header></Header>

      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="md" style={notFound ? { display: 'none' } : {}}>
          <SingleBookDetails book={book}  />
        </Container>
        <Container maxWidth="md" style={notFound ? {marginTop: 100} : { display: 'none' }}>
          This book was not found! <Link to="/">Let's go back.</Link>
        </Container>
      </React.Fragment>
    </div>
  );
}

export default ConnectedComponent(ViewBook);
