import {
  GET_RECOMMENDED_BOOKS,
  GET_RECOMMENDED_BOOKS_SUCCESS,
  GET_RECOMMENDED_BOOKS_ERROR,
} from './actions';

const initialState = {
  loading: false,
  lastUpdated: 0,
  books: [],
  errorMessage: '',
};

function booksState(state = initialState, action) {
  switch (action.type) {
    case GET_RECOMMENDED_BOOKS:
      return {
        ...state,
        loading: true,
      };
    case GET_RECOMMENDED_BOOKS_SUCCESS:
      return {
        ...state,
        loading: false,
        lastUpdated: (new Date()).getTime(),
        books: action.data.data.recommendedBooks,
      };
    case GET_RECOMMENDED_BOOKS_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };

    default:
      return state;
  }
}

export default booksState;
