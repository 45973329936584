import { Analytics, Firebase } from './firebase';
import { AuthService } from './auth';
import { ApolloService } from './lib/apollo';
import gql from 'graphql-tag';

class UsersService {
  static async userList() {
    let user;

    try {
      user = await AuthService.getUser();
    } catch (error) {
      console.error('Could not get user')
      console.error(error);
    }

    const client = await ApolloService.client(user.authToken);

    const query = gql`
      query {
        userList {
          bio
          company
          location
          name
          photo
          role
          username
          recommendations {
            text
            book {
              id
              title
              subtitle
              cover
            }
          }
        }
      }
    `;

    const result = await client.query({ query });
    return result;
  }
}

export { UsersService };
