import React from 'react';
import fetch from 'cross-fetch';
import { Analytics } from '../../services/firebase';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';


const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    border: "0px solid",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  textbox: {
    width: 180,
    backgroundColor: '#F6F6F6',
    [theme.breakpoints.up('sm')]: {
      width: 350,
    },
    borderRadius: 50,
    '& .MuiInputBase-root': {
      borderRadius: 50,
      paddingLeft: '12px!important',
    },
    '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #d1d1d1',
    },
    '& .MuiInputBase-root:focus .MuiOutlinedInput-notchedOutline': {
      border: '1px solid red',
    },
    '& .MuiAutocomplete-root': {
      backgroundColor: "#eee",
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid transparent',
    },
    '& .MuiInputAdornment-root': {
      color: '#767676',
      opacity: '0.8',
    },
    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
      width: '0.8em',
      height: '1.2em',
    },
    '& .MuiInputBase-input': {
      fontSize: 14,
      padding: '3px 4px 4.5px 0px!important',
      color: '#000',
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `#d1d1d1 !important`,
      borderWidth: 1,
    },
  },
  cssFocused: {},
  notchedOutline: {},
  noResults: {
    position: 'absolute',
    background: '#fff',
    padding: '8px 14px',
    borderRadius: '0px 0px 4px 4px',
    marginTop: '0px',
    marginLeft: '22px',
    fontSize: '1em',
    border: 'solid 3px #f6f6f6',
    borderTop: 'solid 0px #f6f6f6',
  },
  noResultsHidden: {
    display: 'none',
  },
  addit: {
    color: '#1B59F6',
  },

}));

function SearchBox({ history }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [query, setQuery] = React.useState('');
  const [debounce, setDebounce] = React.useState('');
  const [loading, setLoading] = React.useState('');
  const [showNoResults, setShowNoResults] = React.useState(false);
  // const loading = open && options.length === 0;

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const doSearch = async () => {
    if (query.length >= 2) {
      setLoading(true)
      setShowNoResults(false)
      fetch(`https://us-central1-pmlib-static.cloudfunctions.net/search?query=${query}`)
          .then(response => response.json())
          .then(response => {
            // console.log('search response', response);
            setLoading(false)
            const opts = response.result.filter(m => m.score <= 0.5).splice(0, 15);
            // console.log('searchresults', opts)

            if (opts.length === 0) {
              setShowNoResults(true)
              setTimeout(() => setShowNoResults(false), 8000)
            } else {
              setOptions(opts);
            }
          })
          .catch(error => {
            setLoading(false)
            // console.log('error completing the search', error);
          });
      Analytics.logEvent('search', { query })
    }
  }

  const handleChange = (e) => {
    setQuery(e.target.value);

    if (debounce) {
      clearTimeout(debounce);
    }

    setDebounce(setTimeout(() => {
      doSearch();
    }, 500))
  }

  const handleNavigateToBook = slug => {
    history.push(`/b/${slug}`);
  }

  return (
    <div className={classes.search}>
      <Autocomplete
        className={classes.textbox}
        variant="outlined"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(option, value) => {
          if (typeof(option) === 'object' && typeof(value) === 'object') {
            return option.item.title === value.item.title
          } else {
            return option;
          }
        }}
        getOptionLabel={option => {
          if (typeof(option) === 'object') {
            return option.item.title;
          } else {
            return option;
          }
        }}
        onChange={(e, value) => {
          if (value && typeof(value) === 'object') { // real values will be an object like {slug: ..., title: ...}
            handleNavigateToBook(value.item.slug);
          }
        }}
        options={options}
        // loading={loading}
        freeSolo
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search for book titles to recommend"
            variant="outlined"
            value={query}
            onChange={handleChange}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              startAdornment: (
                <InputAdornment position="start" size={20}>
                  <SearchRoundedIcon />
                </InputAdornment>
              ),
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
        )}
      />
      <div className={showNoResults ? classes.noResults : classes.noResultsHidden}>
        This book doesn't exist yet. <a href="/addbook" className={classes.addit}>Suggest it.</a>
      </div>
    </div>
  );
}

export { SearchBox };
