import { AuthService } from './auth';

// const ENDPOINT_BASE = 'http://localhost:5000';
const ENDPOINT_BASE = 'https://europe-west1-pmlib-webapp.cloudfunctions.net';
// const ENDPOINT_BASE = 'http://api.submeta.co:2000';

class HttpService {
  static async post(endpoint, body) {
    const user = await AuthService.getUser();

    return await fetch(`${ENDPOINT_BASE}/${endpoint}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${user.authToken}`,
        'Content-Type': 'application/json',        
      },
      body: JSON.stringify(body),
    }).then(doc => doc.json());
  }

  static async get(endpoint) {
    const user = await AuthService.getUser();

    return await fetch(`${ENDPOINT_BASE}/${endpoint}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${user.authToken}`,
        'Content-Type': 'application/json',        
      },
    }).then(doc => doc.json());
  }
}

export { HttpService }