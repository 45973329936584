import React, { useState, useEffect } from 'react';
import ConnectedComponent from '../ConnectedComponent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import CommaSeparatedListAuthors from '../Elements/CommaSeparatedListAuthors';
import FormGroup from '@material-ui/core/FormGroup';
import Tooltip from '@material-ui/core/Tooltip';
import ThumbUpAltRoundedIcon from '@material-ui/icons/ThumbUpAltRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

const useStyles = makeStyles((theme) => ({
    formRoot: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 'calc(100% - 8px)',
      },
    },
    root: {
      display: 'flex',
      marginLeft: '0px!important',
      marginRight: 4,
    },
    listItemIcon: {
      minWidth: '26px',
      color: "rgba(0, 0, 0, 1)",
    },
    menu: {
      backgroundColor: 'rgba(1, 1, 1, 0.2)',
    },
    dialogActionsRight: {
      justifyContent: "flex-end",
    },
  }));


function RecommendationsNoteDialog({
      authState, booksState, profileState, // state
      recommendBook, editRecommendation, showSuccessMessage, history, // actions
      book, small // component props
  }) {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const [recId, setRecId] = useState('');
  const [processing, setProcessing] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const ownRec = book.recommendations.find(r => r.user.username === profileState.profile.username);

    if (ownRec) {
      setText(ownRec.text || '');
      setRecId(ownRec.id);
    }
  }, [book, booksState.lastUpdated, profileState.profile.username]);

  const handleClickOpen = () => {
    setText('');
    setProcessing(false);
    setOpen(true);
  };

  const handleClose = () => {
    setText('');
    setProcessing(false);
    setOpen(false);
  };

  const handleRecommend = async () => {
    setProcessing(true);
    await recommendBook({ bookId: book.id, text });
    await showSuccessMessage('Your recommendation has been added!');
    setOpen(false);
  }

  const handleEditRecommend = async () => {
    setProcessing(true);
    await editRecommendation({ bookId: book.id, id: recId, text });
    await showSuccessMessage('Your recommendation has been updated.');
    setOpen(false);
  }

  const loggedin = authState.loggedin;

  if (loggedin) {
    return (
      <div className={classes.root}>
          {recId ?
            <Tooltip title="Edit or delete your recommendation" placement="top">
              <Button
                size={small ? 'small' : 'large'}
                variant="outlined"
                color="primary"
                aria-controls="simple-menu"
                aria-haspopup="true"
                disableElevation
                onClick={handleClickOpen}
              >
                Recommended
              </Button>
            </Tooltip> :
            <Tooltip title="Recommend this book to others" placement="top">
              <Button
                size={small ? 'small' : 'large'}
                variant={small ? 'outlined' : 'contained'}
                color={small ? 'default' : 'primary'}
                aria-controls="simple-menu"
                aria-haspopup="true"
                disableElevation
                onClick={handleClickOpen}
                startIcon={<ThumbUpAltRoundedIcon />}
              >
                Recommend
              </Button>
            </Tooltip>
          }

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

          {recId ?
            <DialogTitle id="form-dialog-title">Edit your recommendation for <br/><b>"{book.title} by <CommaSeparatedListAuthors authors={book.authors}/>"</b></DialogTitle>
            :
            <DialogTitle id="form-dialog-title">Recommend <br/><b>"{book.title} by <CommaSeparatedListAuthors authors={book.authors}/>"</b></DialogTitle>
          }

          <DialogContent>
            <FormGroup row>
              <form className={classes.formRoot} noValidate autoComplete="off">
                <DialogContentText color="default">
                  Add a personal note to your recommendation to let other users know what you learned from this book.
                </DialogContentText>
                {recId ?
                  <TextField
                    id="outlined-multiline-static"
                    label="Your Note"
                    multiline
                    rows={8}
                    placeholder="I recommend this book because ..."
                    variant="outlined"
                    value={text}
                    onChange={e => setText(e.target.value)}
                  />
                :
                  <TextField
                    id="outlined-multiline-static"
                    label="Your Note"
                    multiline
                    rows={8}
                    placeholder="I recommend this book because ..."
                    variant="outlined"
                    onChange={e => setText(e.target.value)}
                  />
                }
              </form>
            </FormGroup>
          </DialogContent>

          <DialogActions className={classes.dialogActionsRight}>

            {recId ?
              <Button
                onClick={handleEditRecommend}
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                startIcon={<EditRoundedIcon />}
                disabled={text.length <= 0 || processing}
              >
                Edit
              </Button> :
              <Button
                onClick={handleRecommend}
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                startIcon={<CheckRoundedIcon />}
                disabled={text.length <= 0 || processing}
              >
                Save
              </Button>
            }

            <Button
              onClick={handleClose}
              size="small"
              variant="outlined"
              color="default"
              disableElevation
            >
              Cancel
            </Button>
          </DialogActions>

        </Dialog>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <Tooltip title="Recommend this book to others" placement="top">
          <Button
            size={small ? 'small' : 'large'}
            variant={small ? 'outlined' : 'contained'}
            color={small ? 'default' : 'primary'}
            aria-controls="simple-menu"
            aria-haspopup="true"
            disableElevation
            onClick={() => history.push('/login')}
            startIcon={<ThumbUpAltRoundedIcon />}
          >
            Recommend
          </Button>
        </Tooltip>
      </div>
    );
  }
}

export default ConnectedComponent(RecommendationsNoteDialog)
