import React from 'react';
import { Analytics } from '../../services/firebase';
import Button from '@material-ui/core/Button';
import ConnectedComponent from '../ConnectedComponent';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Container } from '@material-ui/core';
import ChipsListCategories from '../Elements/ChipsListCategories';
import RecommendationsList from '../UserInteraction/RecommendationsList';
import CommaSeparatedListAuthors from '../Elements/CommaSeparatedListAuthors';
import Tooltip from '@material-ui/core/Tooltip';
import RecommendationsNoteDialog from '../UserInteraction/RecommendationsNoteDialog';

const useStyles = makeStyles((theme) => ({
    main: {
      textAlign: 'center',
      margin: 'auto',
    },
    content: {
      padding: '0px!important',
      margin: 0,
    },
    media: {
      height: 246,
      backgroundColor: '#F6F6F6',
      width: '100%',
      paddingTop: 38,
      paddingBottom: 38,
      borderRadius: 8,
    },
    details: {
      flex: '1 0 auto',
    },
    img: {
      height: 168,
      objectFit: 'contain',
      width: 'initial',
      boxShadow: '0 2px 1px rgba(0,0,0,0.09), 0 4px 2px rgba(0,0,0,0.09), 0 8px 4px rgba(0,0,0,0.09), 0 16px 8px rgba(0,0,0,0.09), 0 32px 16px rgba(0,0,0,0.09)',
      margin: 'auto',
    },
    title: {
      lineHeight: 1.2,
      fontWeight: 600,
      margin: '16px auto 24px',
    },
    subtitle: {
      lineHeight: 1.2,
      fontSize: 20,
      fontWeight: 400,
      margin: '8px auto 16px',
      width: 'calc(90% - 16px)',
    },
    author: {
      lineHeight: 1.3,
      fontSize: 18,
      fontWeight: 400,
      margin: '16px auto 8px',
    },
    information: {
      padding: '16px 24px 8px 24px!important',
      margin: '0 auto 16px',
      width: 'calc(90% - 16px)',
      backgroundColor: '#EFEFEF',
      borderRadius: 8,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    notes: {
      padding: '16px 0!important',
      margin: '0 auto',
      width: 'calc(90% - 16px)',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    body1: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
    },
    buttons: {
      '& > *': {
          margin: theme.spacing(1),
      },
      padding: '26px 0px 20px',
    },
    buttonCenter: {
      textAlign: 'center',
      '& > *': {
        margin: theme.spacing(0.5),
        display: 'initial',
        [theme.breakpoints.down('xs')]: {
          marginBottom: 12,
          display: 'block',
        },
      },
    },
    chips: {
      margin: '16px auto 0px',
      padding: 0,
    },
    recommendations: {
      padding: 0,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    header: {
      marginBottom: 12,
      fontWeight: 700,
    },
    headerTopSpace: {
      marginTop: 8,
      marginBottom: 12,
      fontWeight: 700,
    },
    purchaseLink: {
      textDecoration: 'none',
    },
    description: {
      marginBottom: 12,
    }
}));

function BookDetails({ book, recommendations, userRecommended }) {
    const classes = useStyles()

    let recommendationsExist = false;

    if (Array.isArray(recommendations) && recommendations.length) {
      recommendationsExist = true;
    };

    return (
        <div>
          <div className={classes.media} md={12}>
            <CardMedia
                component="img"
                alt={book.title}
                image={book.cover}
                title={book.title}
                className={classes.img}
            />
          </div>

          <Container className={classes.details} md={12} >
              <CardContent className={classes.main}>
                <Typography gutterBottom variant="h3" component="h1" className={classes.title}>
                    {book.title}
                </Typography>
                <Typography variant="h5" component="h2" className={classes.subtitle}>
                    {book.subtitle}
                </Typography>
                <Typography variant="h6" component="h3" className={classes.author}>
                    by <CommaSeparatedListAuthors authors={book.authors} />
                </Typography>
                <Container className={classes.buttons} md={12}  >

                  <div className={classes.buttonCenter}>

                    <RecommendationsNoteDialog book={book} />

                    <Tooltip title="Purchase the book on Amazon" placement="top">
                      <a href={book.amazon_link} target="_blank" rel="noopener noreferrer" className={classes.purchaseLink}>
                        <Button
                          color="default"
                          size="large"
                          disableElevation
                          onClick={() => Analytics.logEvent('get_book', { bookId: book.id, location: 'BookDetails' })}
                        >
                          Get this book
                        </Button>
                      </a>
                    </Tooltip>
                  </div>

                </Container>
              </CardContent>

              <CardContent className={classes.information} >
                  <Typography variant="h6" component="h4"  className={classes.header}>
                      More Information
                  </Typography>
                  <Typography variant="body1" component="p" className={classes.description}>
                      {book.description}
                  </Typography>
                  <Typography variant="body1" component="p" className={classes.description}>
                      {/* {book.pages} pages, {book.publisher}, {book.year} */}
                      {book.pages} pages, {book.year}
                  </Typography>
                  <Container className={classes.chips}>
                      <ChipsListCategories book={book} />
                  </Container>
              </CardContent>
              {recommendationsExist ?
                <CardContent className={classes.notes} >
                  <Typography variant="h6" component="h4" className={classes.headerTopSpace} >
                    Recommended by
                  </Typography>
                  <Container className={classes.recommendations}>
                    <RecommendationsList recommendations={recommendations} />
                  </Container>
                </CardContent>
              :
                <CardContent className={classes.information} >
                  <Typography variant="h6" component="h4" className={classes.header} >
                    Not recommended yet
                  </Typography>
                  <Typography variant="body1" component="p" className={classes.description} >
                    Be the first to recommend this book, add a personal note and share it on your profile.
                  </Typography>
                </CardContent>
              }

          </Container>
        </div>
    );
  }

export default ConnectedComponent(BookDetails);
