import React from 'react';
import ConnectedComponent from '../ConnectedComponent';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import BookDetails from './BookDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0),
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    margin: 'auto',
    marginTop: '82px',
  },
  imageBox: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  image: {
    width: 200,
    height: 200,
    [theme.breakpoints.down('xs')]: {
      margin: '0px auto 12px',
    },
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '100%',
  },
  bookDetails: {
    borderRadius: 8,
    marginLeft: 20,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      minWidth: 200,
      textAlign: 'center',
    },
    '& > *': {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(4),
  },
}));

function SingleBookDetails({ book, userRecommended, recommendations }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid className={classes.paper}>
        <Grid container>
          <Grid item xs={12} sm container className={classes.bookDetails}>

            <BookDetails book={book} recommendations={book.recommendations} />

          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

{/* export { SingleBookDetails }; */}
export default ConnectedComponent(SingleBookDetails);
