import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';


import Skeleton from '@material-ui/lab/Skeleton';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import ConnectedComponent from '../ConnectedComponent';

import { ProfileService } from '../../services/profile';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    marginTop: '82px',
  },
  imageBox: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  image: {
    width: 200,
    height: 200,
    [theme.breakpoints.down('xs')]: {
      margin: '0px auto 12px',
    },
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '100%',
  },
  userDetails: {
    backgroundColor: '#F6F6F6',
    padding: '24px!important',
    borderRadius: 8,
    marginLeft: 20,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      minWidth: 200,
      textAlign: 'center',
    },
    '& > *': {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  notLoading: {
    display: 'none',
  },
  load: {
    display: 'block',
    marginLeft: theme.spacing(1),
    color: '#fff',
  },
  profileButton: {
    marginLeft: 4,
  }
}));

function UserEditInformation({ profile, profileState, saveProfile, saveUsername, loading, showSuccessMessage, resetState, history }) {
  const classes = useStyles();

  const [loaded, setLoaded] = React.useState(false);
  // const [id, setId] = React.useState(profile.id || '');
  const [name, setName] = React.useState(profile.name || '');
  const [photo, setPhoto] = React.useState(profile.photo || '');
  const [location, setLocation] = React.useState(profile.location || '');
  const [role, setRole] = React.useState(profile.role || '');
  const [company, setCompany] = React.useState(profile.company || '');
  const [bio, setBio] = React.useState(profile.bio || '');
  const [email, setEmail] = React.useState(profile.email || '');
  const [username, setUsername] = React.useState(profile.username || '');

  const [unameOriginal, setUnameOriginal] = React.useState(profile.username || '');
  const [unameCheckLoading, setUnameCheckLoading] = React.useState(false);
  const [unameAvailable, setUnameAvailable] = React.useState(true);
  const [debounce, setDebounce] = React.useState('');

  React.useEffect(() => {
    if (profileState.lastState === 'SET_PROFILE_SUCCESS') {
      showSuccessMessage('Profile successfully updated');
      history.push(`/u/${username}`);
      resetState();
    }
  }, [profileState.lastState]);

  useEffect(() => {
    // if (!loading && !loaded) {
      setLoaded(true);

      setName(profile.name);
      setPhoto(profile.photo);
      setLocation(profile.location);
      setRole(profile.role);
      setCompany(profile.company);
      setBio(profile.bio);
      setEmail(profile.email);
      setUsername(profile.username);
      setUnameOriginal(profile.username);
    // }
  }, [profile.name]);

  const save = () => {
    // handleSave({id, name, photo, location, role, company, bio, email, username});
    saveProfile({name, location, role, company, bio});

    if (username !== unameOriginal) {
      saveUsername({ username });
      setUnameOriginal(username);
    }
  };

  const sanitizeUsernameInput = (input) => {
    return input.replace(/\W/g, '');
  };

  const handleUsernameEdit = (e) => {
    setUsername(sanitizeUsernameInput(e.target.value));
    const usernameValue = sanitizeUsernameInput(e.target.value)

    if (debounce) {
      clearTimeout(debounce);
    }
    setDebounce(setTimeout(() => {
      setUnameCheckLoading(true);
      verifyUsername(usernameValue)
          .then(result => {
            // console.log('result', result)
            setUnameCheckLoading(false);
            setUnameAvailable(result);
          })
          .catch(err => {
            // console.log('error checking username', err)
            setUnameCheckLoading(false);
          });
    }, 500))
  }

  const verifyUsername = async (usernameValue) => {
    const available = await ProfileService.usernameAvailable(usernameValue);
    // console.log('available', available);
    return available;
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.paper}>
        <Grid container>
          <Grid item className={classes.imageBox}>
            <Box className={classes.image}>
              <Tooltip title="The profile image is currently not editable and is controlled by your Google Account. If you want to change it, please send an email to alex@thepmlibrary.com." placement="bottom">
                <img src={photo} alt={name} className={classes.img} referrerPolicy="no-referrer" />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} sm container className={classes.userDetails}>
            <Typography gutterBottom variant="h4" component="h1" className={classes.title} >
              Update your profile
            </Typography>

            <TextField
              required
              id="outlined-required"
              label="Your first and last name"
              variant="outlined"
              value={name}
              onChange={e => setName(e.target.value)}
            />

            <TextField
              required
              id="outlined-required"
              label="Username"
              variant="outlined"
              value={username}
              // onChange={e => setUsername(e.target.value)}
              onChange={handleUsernameEdit}
              helperText="Please make sure that your username is not taken already. This is the identifier for your PM Library profile URL."
              error={!unameAvailable}

              // Use this to visually show user whether the username is available or not
              InputProps={{
                endAdornment: (
                  unameCheckLoading ?
                      <CircularProgress size={12} /> :
                          unameAvailable ?
                              <CheckIcon /> :
                              <ClearIcon />
                ),
              }}
            />

            <TextField
              required
              id="outlined-required"
              label="Current Position"
              variant="outlined"
              value={role}
              onChange={e => setRole(e.target.value)}
            />

            <TextField
              id="outlined-required"
              label="Company"
              variant="outlined"
              value={company}
              onChange={e => setCompany(e.target.value)}
            />

            <TextField
              id="outlined-required"
              label="City / Country / Region"
              variant="outlined"
              value={location}
              onChange={e => setLocation(e.target.value)}
            />

            <TextField
              id="outlined-required"
              label="Headline / About you"
              variant="outlined"
              multiline={true}
              value={bio}
              onChange={e => setBio(e.target.value)}
            />

            <Grid container>

                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={unameCheckLoading}
                  onClick={save}
                  startIcon={<SaveRoundedIcon />} >
                  Save
                  <CircularProgress className={loading ? classes.load : classes.notLoading} size={20} />
                </Button>

            </Grid>

            <Typography gutterBottom variant="caption" component="div">
              Please note: Your profile information will be accessible to users logged-in as well as to everyone logged-out. If you want to delete your account please write to info@thepmlibrary.com.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ConnectedComponent(UserEditInformation);
