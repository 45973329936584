import { UsersService } from '../../services/users';
import { asyncStateFunction } from '../util';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const userList = (opts) => asyncStateFunction(
  'usersState',
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  UsersService.userList);
