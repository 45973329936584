import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Avatar from '@material-ui/core/Avatar';
import { Avatar } from '../Avatar';
import Button from '@material-ui/core/Button';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({  
  root: {
    display: 'flex',    
    marginLeft: 4,  
    marginRight: 4,
    height: 30,         
  },
  button: {
    minWidth: 0,
    padding: 0,
    "&:hover": {
      backgroundColor: "#FFF",
    },    
  },    
  inline: {
    display: 'inline',
  },  
}));

const theme = createMuiTheme({
  overrides: {
    MuiAvatarGroup: {
      avatar: {                
        border: '1px solid #ffffff',
        height: 32,        
        width: 32,
        backgroundColor: '#F6F6F6',        
        fontSize: 12,
        color: '#292A2C',  
        marginLeft: '-5px',        
      }
    }
  }
});

function RecommendationsAvatarGroup({ recommendations }) {
    const classes = useStyles();   

    return (
        <div className={classes.root}>
            <Button 
              size="small"
              className={classes.button}              
              disableTouchRipple={true}
              disableFocusRipple={true} >
              <ThemeProvider theme={theme}>

                <AvatarGroup 
                  max={4} >
                  {recommendations.map((rec, i) => 
                    <Avatar
                      key={i}
                      alt={rec.user.name}
                      src={rec.user.photo}
                      size={30}
                      style={{marginLeft:'-5px',marginTop:'1px',}}
                      className={classes.item} />                      
                    )}    
                </AvatarGroup>
                  
              </ThemeProvider>
            </Button>
        </div>
    );
}

export default RecommendationsAvatarGroup;
