import { ApolloService } from './lib/apollo';
import gql from 'graphql-tag';

class PublicProfileService {
  static async getPublicProfile({ username }) {
    const client = await ApolloService.client();

    const query = gql`
      query Profile($username: String) {
        profile(username: $username) {
          username
          name
          role
          photo
          location
          company
          bio
  
          recommendations {
            text
            book {
              id
              slug
              title
              subtitle
              amazon_link
              backup_link
              cover
              description
              pages
              
              year
      
              authors {
                slug
                name
              }
              
              recommendations {
                id
                text
                user {
                  username
                  name
                  role
                  company
                  photo
                }
              }
            }
          }
        }
      }
    `;
    
    const variables = { username }

    return client.query({ query, variables });
  }
}

export { PublicProfileService };
