import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ConnectedComponent from '../ConnectedComponent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    marginTop: '82px',
  },
  imageBox: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  image: {
    width: 200,
    height: 200,
    [theme.breakpoints.down('xs')]: {
      margin: '0px auto 12px',
    },
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '100%',
  },
  userDetails: {
    backgroundColor: '#F6F6F6',
    padding: '24px!important',
    borderRadius: 8,
    marginLeft: 20,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      minWidth: 200,
      textAlign: 'center',
    },
    '& > *': {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  notLoading: {
    display: 'none',
  },
  load: {
    display: 'block',
    marginLeft: theme.spacing(1),
    color: '#000',
  },
  description: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 24,
      textAlign: 'center',
    },
  },

  question: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 24,      
      position: 'relative',
      display: 'block',
      width: '100%',
    },
  },

  questionText: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 12,      
      position: 'relative',
      display: 'block',
      width: '100%',
    },
  },

  questionLink: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 12,
      width: '100%',
      position: 'relative',
      display: 'block',
    },
  },


  button: {    
    marginRight: 12,   
    [theme.breakpoints.down('xs')]: {
      marginBottom: 12,   
      position: 'relative',
      display: 'block',   
      marginLeft: 'auto',    
      marginRight: 'auto',  
    },           
  }, 
}));

function AddBookForm({ handleSave, showSuccessMessage, loading, history }) {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [subtitle, setSubTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [recommendation, setRecommendation] = useState('');

  const [autoCompleteLoading, setAutoCompleteLoading] = useState(false);
  const [autoCompleteTimer, setAutoCompleteTimer] = useState();
  // const [autoCompleteBook, setAutoCompleteBook] = useState();
  const [acTitle, setAcTitle] = useState('');
  const [acAuthors, setAcAuthors] = useState('');
  const [acSlug, setAcSlug] = useState('');

  const [readyStepTwo, setReadyStepTwo] = useState(false);

  const submitBook = () => {
    handleSave(title, subtitle, author, recommendation);
    showSuccessMessage('Thanks, your book has been submitted for review! You will get the confirmation from us in the next 48hrs.');
    history.push('/');
  }

  const handleTitleTyping = e => {
    setTitle(e.target.value);
    setAcTitle('')
    setAcAuthors('')
    setAcSlug('')

    if (autoCompleteTimer) {
      clearTimeout(autoCompleteTimer);
    }

    setAutoCompleteTimer(setTimeout(doSearch, 700));
  }

  const handleDeclineSuggestion = () => {
    setReadyStepTwo(true);
    setAcTitle('');
  }

  const doSearch = async () => {
    if (title.length >= 2) {
      setAutoCompleteLoading(true);

      const result = await fetch(`https://us-central1-pmlib-static.cloudfunctions.net/search?query=${title}`)
          .then(r => r.json());

      if (result.result.length === 0) {
        setReadyStepTwo(true);
        setAutoCompleteLoading(false);
      } else {
        const bestResult = result.result.shift();
        const authors = bestResult.item.authors.map(a => a.name).join(', ');
        setAcSlug(bestResult.item.slug);
        setAcTitle(bestResult.item.title);
        setAcAuthors(authors);
        setAutoCompleteLoading(false);
      }
    }
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.paper}>
        <Grid container>
          <Grid item xs={12} sm container className={classes.userDetails}>
            <Typography gutterBottom variant="h4" component="h1" className={classes.title} >
              Add a book to PM Library
            </Typography>
            <Typography color="default" gutterBottom className={classes.description}>
              If you are missing a book in the library just send us the following infos about the book and a short recommendation. We'll get it added as quick as possible, so the whole community can benefit from your contribution.<br/><br/><u>Please double check if the book already exists in the library. Just type in the book title in the form below.</u>
            </Typography>

            <TextField
              required
              id="outlined-required"
              label="Book Title"
              variant="outlined"
              value={title}
              // onChange={e => setTitle(e.target.value)}
              onChange={handleTitleTyping}
            />
            <div style={autoCompleteLoading ? {} : {display: 'none'}}>Loading ...</div>
            <div style={acTitle ? {} : {display: 'none'}} className={classes.question}>
              <Typography color="default" gutterBottom className={classes.questionText}>
                Did you mean the book: <Link className={classes.questionLink} to={`/b/${acSlug}`}>{acTitle} by {acAuthors}?</Link>
              </Typography>

              <Button
                size="small"
                variant="contained"
                color="primary"
                disableElevation                
                onClick={() => history.push(`/b/${acSlug}`)}
                className={classes.button}
              >
                Yes! I want to recommend it.
                <CircularProgress className={loading ? classes.load : classes.notLoading} size={20} />
              </Button>              

              <Button
                size="small"
                variant="outlined"
                color="default"
                disableElevation
                onClick={handleDeclineSuggestion}
                className={classes.button}
              >
                No, add a new book
                <CircularProgress className={loading ? classes.load : classes.notLoading} size={20} />
              </Button>

            </div>

            <span style={readyStepTwo ? {} : {display: 'none'}}>
              Got it! Would you mind sharing a few more details?
            </span>
            <TextField
              style={readyStepTwo ? {} : {display: 'none'}}
              id="outlined-required"
              label="Subtitle"
              variant="outlined"
              value={subtitle}
              onChange={e => setSubTitle(e.target.value)}
            />

            <TextField
              style={readyStepTwo ? {} : {display: 'none'}}
              required
              id="outlined-required"
              label="Book Author(s)"
              variant="outlined"
              value={author}
              onChange={e => setAuthor(e.target.value)}
            />

            <TextField
              style={readyStepTwo ? {} : {display: 'none'}}
              required
              id="outlined-required"
              label="Your Recommendation / Opinion"
              variant="outlined"
              value={recommendation}
              onChange={e => setRecommendation(e.target.value)}
            />

            <Grid container style={readyStepTwo ? {} : {display: 'none'}}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                onClick={submitBook}
              >
                Submit
                <CircularProgress className={loading ? classes.load : classes.notLoading} size={20} />
              </Button>
            </Grid>

            {/* <h3>Did you mean?</h3>
            <div style={autoCompleteLoading ? {} : {display: 'none'}}>Loading ....</div>


             */}

          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ConnectedComponent(AddBookForm);