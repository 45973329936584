import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'left',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
      fontWeight: 700,
    },
  }));

function ChipsListCategories({ book }) {
  const classes = useStyles();

  return (
    <span className={classes.root}>
      {/* {book.categories.map((category, i) => (
        <Chip key={i} label={category.name} component="a" href="#chip" clickable variant="outlined"></Chip>
      ))}   */}
    </span>
  );
}

export default ChipsListCategories;