import { Analytics, Firebase } from './firebase';
import { AuthService } from './auth';
import { ApolloService } from './lib/apollo';
import gql from 'graphql-tag';

class ProfileService {
  // static async getProfile() {
  //   let user;

  //   try {
  //     user = await AuthService.getUser();
  //   } catch (error) {
  //     console.error('Could not get user')
  //     console.error(error);
  //   }

  //   return Firebase.firestore()
  //       .collection('profiles')
  //       .doc(user.uid)
  //       .get()
  //       .then(doc => doc.data());
  // }

  static async getProfile(username) {
    let user;

    try {
      user = await AuthService.getUser();
    } catch (error) {
      console.error('Could not get user')
      console.error(error);
    }

    const client = await ApolloService.client(user.authToken);

    const query = gql`
      query {
        myProfile {
          bio
          company
          location
          name
          photo
          role
          username
        }
      }
    `;
    
    const variables = { username };
    
    const result = await client.query({ query, variables });
    return result;
  }

  static async setUsername({ username }) {
    let user;

    try {
      user = await AuthService.getUser();
    } catch (error) {
      console.error('Could not get user')
      console.error(error);
    }

    const client = await ApolloService.client(user.authToken);

    const mutation = gql`
      mutation SetUsername($username: String) {
        setUsername(username: $username)
      }
    `;
    
    const variables = { username }

    Analytics.logEvent('change_username');

    return client.mutate({ mutation, variables });
  }

  static async setProfile({ name, location, role, company, bio }) {
    let user;

    try {
      user = await AuthService.getUser();
    } catch (error) {
      console.error('Could not get user')
      console.error(error);
    }

    const client = await ApolloService.client(user.authToken);
    const mutation = gql`
      mutation SetProfile($name: String, $location: String, $role: String, $company: String, $bio: String) {
        setProfile(name: $name, location: $location, role: $role, company: $company, bio: $bio)
      }
    `;
    
    const variables = { name, location, role, company, bio };

    Analytics.logEvent('edit_profile');

    return client.mutate({ mutation, variables });
  }

  static async usernameAvailable(username) {
    let user;

    try {
      user = await AuthService.getUser();
    } catch (error) {
      console.error('Could not get user')
      console.error(error);
    }

    const client = await ApolloService.client(user.authToken);

    const query = gql`
      query UsernameCheck($username: String) {
        usernameCheck(username: $username)
      }
    `;
    
    const variables = { username };
    
    const result = await client.query({ query, variables });
    return result;
  }
}

export { ProfileService };
