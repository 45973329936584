import { HttpService } from './http';
import { Analytics } from './firebase';

class FeedbackService {
  static async submitFeedback(text) {
    Analytics.logEvent('give_feedback');
    return HttpService.post('submitFeedback', {text});
  }
}

export { FeedbackService };
