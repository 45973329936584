import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import logoBig from '../assets/logo-pm-library_s.png';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';




const root = {
  height: '100vh',  
  minWidth: 400,
}
const paper = {   
  display: 'flex',
  boxSizing: 'box-border',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',    
}

const logoDesktop = {  
  height: '60px',
  margin: "24px 0px 72px",
}
const intro = {  
  width: 300,
  marginTop: 12,
}

const submit = {
  margin: "24px 0px",   
}

function Oops() {
  return (
    <div>      
      <Grid  style={root}>
        <CssBaseline />
        <Grid item fullwidth >
          <div style={paper}>                                        
            <Box >
              <img alt="PM Library Logo" src={logoBig} style={logoDesktop} />                           
            </Box>
            <Typography component="h1" variant="h4" gutterBottom>
            Oops! 
            </Typography>
            <Typography component="p" variant="body1" align="center" display="block" style={intro}>
            Looks like something went wrong. Let's go back to the start...
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              style={submit}                  
              href="/"            
              startIcon={<ArrowForwardRoundedIcon />}      
            >                  
              Click here
            </Button>
          </div>          
        </Grid>        
      </Grid>
    </div>
  );
}
  

export default Oops;