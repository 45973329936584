import { Analytics, Firebase, GoogleProvider } from './firebase';

function userDoc(user, token) {
  return {
    uid: user.uid,
    email: user.email,
    name: user.displayName,
    photo: user.photoURL,
    authToken: token,
  };
}

class AuthService {

  // TODO: add password confirmation
  static userSignup(email, password) {
    return Firebase.auth()
        .createUserWithEmailAndPassword(email, password);
  }

  static userLogin(email, password) {
    return Firebase.auth()
        .signInWithEmailAndPassword(email, password);
  }

  static userLoginGoogle() {
    return Firebase.auth().signInWithPopup(GoogleProvider);
  }

  static userUpdate(user) {
    // console.log('updating user obj', userDoc(user));
    const ref = Firebase.firestore().collection('users').doc(user.uid);
    ref.set({
      datetime: (new Date()).getTime(),
      ...userDoc(user, '')
    }).then();
  }

  static getUser() {
    return new Promise((resolve, reject) => {
      Firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          const currentUser = Firebase.auth().currentUser;

          if (currentUser) {
            // Analytics.setUserID(user.uid);

            currentUser.getIdToken(true)
              .then((token) =>  {
                // this.userUpdate(user);
                resolve(userDoc(user, token));
              });

          } else {
            // this.userUpdate(user);
            resolve(userDoc(user, ''));
          }
        } else {
          reject();
        }
      });
    });
  }

  static signOut = async () => {
    await Firebase.auth().signOut();
  }
}

export { AuthService };
