import React from 'react';
import { Analytics } from '../../services/firebase';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import ConnectedComponent from '../ConnectedComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    marginTop: '82px',
    minWidth: 250,
  },
  imageBox: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  image: {
    width: 200,
    height: 200,
    [theme.breakpoints.down('xs')]: {
      margin: '0px auto 12px',
      width: 150,
      height: 150,
    },
  },
  img: {
    width: 200,
    height: 200,
    margin: 'auto',
    maxHeight: 200,
    maxWidth: 200,
    objectFit: 'cover',
    borderRadius: 200,
    [theme.breakpoints.down('xs')]: {
      width: 150,
      height: 150,
      maxHeight: 150,
      maxWidth: 150,
    },
  },
  userDetails: {
    padding: '24px!important',
    borderRadius: 8,
    marginLeft: 20,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      textAlign: 'center',
    },
    '& > *': {
      width: '100%',
    },
  },
  dialogActionsLeft: {
    justifyContent: "flex-start",
    paddingLeft: 0,
    paddingTop: 12,
    paddingBottom: 0,
    [theme.breakpoints.down('xs')]: {
      justifyContent: "center",
      paddingRight: 0,
    },
  },
  editProfileButton: {
    textDecoration: 'none',
  },
  title: {
    textTransform: 'capitalize',
  },
}));

function UserInformation({ authState, profileState, loading, profile, showSuccessMessage }) {
  const classes = useStyles();

  const loggedin = authState.loggedin;
  const self = profileState.profile;

  // Define profile self or profile other
  let profileSelf = false;

  if (loggedin) {
    if (self.username === profile.username) {
      profileSelf = true;
    } else {
      profileSelf = false;
    }
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item className={classes.imageBox}>
            <Box className={classes.image}>
              {loading ? (
                  <Skeleton variant="circle" width={150} height={150} />
                ) : (
                  profile.photo ? (
                    <img src={profile.photo} alt={profile.name} className={classes.img} referrerPolicy="no-referrer" />
                  ) : (
                    <Skeleton variant="circle" className={classes.image} />
                  )
                )
              }
            </Box>
          </Grid>

          <Grid item xs={12} sm container className={classes.userDetails}>
            <Typography gutterBottom variant="h3" component="h1" className={classes.title}>
              {loading ? (
                <Skeleton variant="rect" width={250} height={40} />
              ) : (
                <span>{profile.name}</span>
              )}
            </Typography>

            {profile.role || profile.location || profile.company || profile.bio ? (
              <Box mr={1} display="inline" style={{marginRight: '0px'}} className={classes.moreUserInfo}>
                {profile.role && (
                  <Typography variant="h6" component="h4">
                    {loading ? (
                      <Skeleton variant="text" />
                    ) : (
                      <span>{profile.role} {profile.company && ("@")} {profile.company}</span>
                    )}
                  </Typography>
                )}

                {profile.location && (
                  <Typography gutterBottom variant="h6" component="h4">
                    {loading ? (
                      <Skeleton variant="text" />
                    ) : (
                      <span>{profile.location}</span>
                    )}
                  </Typography>
                )}

                {profile.bio && (
                  <Typography gutterBottom variant="body1" component="h4">
                    {loading ? (
                      <Skeleton variant="text" />
                    ) : (
                      <span>{profile.bio}</span>
                    )}
                  </Typography>
                )}
              </Box>
            ) : (
              <Box mr={1} display="inline" style={{marginRight: '0px'}} className={classes.moreUserInfo}>
                {loading ? (
                  <>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </>
                ) : (
                  profileSelf ? (
                    <Typography gutterBottom variant="body1" component="h4">
                        We would love to get to know you better. <br/>Fill out your profile.
                    </Typography>
                  ) : (
                    <Typography gutterBottom variant="body1" component="h4">
                        ...
                    </Typography>
                  )
                )}
              </Box>
            )}

            <CardActions className={classes.dialogActionsLeft}>

              {/* TODO SHANE Build Follow function */}

              {/* {!profileSelf && (
                <>
                  {loading ? (
                    <Skeleton variant="rect" width={70} height={28} />
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      disableElevation
                      startIcon={<PersonRoundedIcon />}
                    >
                      Follow
                    </Button>
                  )}

                  <Button
                    size="small"
                    variant="contained"
                    color="default"
                    disableElevation
                    startIcon={<PersonRoundedIcon />}
                  >
                    Following
                  </Button>
                </>
              )} */}

              {/* TODO SHANE Build share function */}
              {loading ? (
                <Skeleton variant="rect" width={70} height={28} />
              ) : (
                <CopyToClipboard text={`https://app.thepmlibrary.com/u/${profile.username}`} onCopy={() => {
                  Analytics.logEvent('share_profile', { username: profile.username, self: profileState.profile.username === profile.username });
                  showSuccessMessage('Copied URL to clipboard')
                }}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="default"
                    disableElevation
                    startIcon={<ShareRoundedIcon />}
                  >
                    Share
                  </Button>
                </CopyToClipboard>
              )}

              {profileSelf ? (
                  <>
                    {loading ? (
                      <Skeleton variant="rect" width={70} height={28} />

                    ) : (

                      <Link to={'/profile'} className={classes.editProfileButton}>
                        {profile.role || profile.location || profile.company || profile.bio ? (
                          <Button
                            size="small"
                            variant="outlined"
                            color="default"
                            disableElevation
                          >
                            Edit profile
                          </Button>
                        ) : (
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            disableElevation
                          >
                            Complete Profile
                          </Button>
                        )}
                      </Link>

                    )}
                  </>
                ) : (<></>)
              }
            </CardActions>
          </Grid>

        </Grid>
      </Grid>
    </div>
  );
}

export default ConnectedComponent(UserInformation);
