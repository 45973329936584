import React, { useEffect } from 'react';
import logoBig from '../assets/logo-pm-library_s.png';

const logoStyle = {
  height: 68,
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: '-34px',
  marginLeft: '-105px',
}

function Loading({ history }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      history.push('/')
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div style={{height: '100vh', width: '100vw'}}>
      <img alt="PM Library Logo" src={logoBig} style={logoStyle} />
    </div>
  );
}


export default Loading;