import React from 'react';
import { Analytics } from '../../services/firebase';
import ConnectedComponent from '../ConnectedComponent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import RecommendationsNoteDialog from '../UserInteraction/RecommendationsNoteDialog';
import BookDetails from './BookDetails';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';


const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    margin: 0,
    padding: 0,
    width: '100%',
  },
  content: {
    padding: '0px!important',
    margin: 0,
  },
  dialogActionsRight: {
    justifyContent: "flex-end",
  },
  purchaseLink: {
    textDecoration: 'none',
  }
}));

function PopupBookDetails({ book, open, handleClose, showSuccessMessage }) {
  const classes = useStyles()

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'md'}
        fullWidth={true}
        scroll='body'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={classes.root}
      >
        <DialogContent dividers className={classes.content}>
          <BookDetails book={book} recommendations={book.recommendations} />
        </DialogContent>

        <DialogActions className={classes.dialogActionsRight}>

          <RecommendationsNoteDialog book={book} small={'true'} />

          <a href={`/b/${book.slug}`} rel="noopener noreferrer" className={classes.purchaseLink}>
            <Button
              size="small"
              variant="outlined"
              color="default"
              disableElevation
              startIcon={<MenuBookRoundedIcon />}
              onClick={() => Analytics.logEvent('show_detail_page', { bookId: book.id, location: 'PopupBookDetails' })}
            >
              Show more details
            </Button>
          </a>

          <a href={book.amazon_link} target="_blank" rel="noopener noreferrer" className={classes.purchaseLink}>
            <Button
              size="small"
              variant="outlined"
              color="default"
              disableElevation
              onClick={() => Analytics.logEvent('get_book', { bookId: book.id, location: 'PopupBookDetails' })}
              startIcon={<ShoppingCartRoundedIcon />}
            >
              Get this book
            </Button>
          </a>

          <CopyToClipboard text={`https://app.thepmlibrary.com/b/${book.slug}`} onCopy={() => {
            Analytics.logEvent('share_book', { bookname: book.slug });
            showSuccessMessage('Copied URL to the book page to clipboard.')
          }}>
            <Button
              size="small"
              variant="outlined"
              color="default"
              disableElevation
              startIcon={<ShareRoundedIcon />}
            >
              Share
            </Button>
          </CopyToClipboard>

          <Button
            size="small"
            variant="outlined"
            color="default"
            disableElevation
            onClick={handleClose}
            startIcon={<ClearRoundedIcon />}
          >
            Close
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConnectedComponent(PopupBookDetails);
