import React from 'react';
import { Analytics } from '../../services/firebase';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',    
      marginLeft: '0px!important',  
      marginRight: 4,
      "& > a": {
        textDecoration: "none",
      },
    },    
    interactionIcon: {
      backgroundColor: '#F6F6F6', 
      borderRadius: 50,
      minWidth: 0,
      padding: 0,
      '& .MuiButton-startIcon': {
        display: 'inherit',
        marginLeft: 0,
        marginRight: 0,
      },    
      "&:hover": {
        backgroundColor: "#EFEFEF",
      },
    },
  }));


function PurchaseButton({ book }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {book.backup_link ? 
        <a href={book.backup_link} target="_blank" rel="noopener noreferrer">
          <Tooltip title="Get this book" placement="top">         
            <Button 
              size="small" 
              variant="outlined" 
              color="default" 
              disableElevation
              onClick={() => Analytics.logEvent('get_book', { bookId: book.id, location: 'PurchaseButton' })}
            >
              Get this book                  
            </Button>
          </Tooltip>
        </a>
      : <a href={book.amazon_link} target="_blank" rel="noopener noreferrer">
        <Tooltip title="Purchase the book on Amazon" placement="top">         
          <Button 
            size="small" 
            variant="outlined" 
            color="default" 
            disableElevation
            onClick={() => Analytics.logEvent('get_book', { bookId: book.id, location: 'PurchaseButton' })}
          >
            Get this book                  
          </Button>
        </Tooltip>
      </a>}
    </div>
  );
}

export default PurchaseButton;