import {
  GET_BOOKS,
  GET_BOOKS_SUCCESS,
  GET_BOOKS_ERROR,
  GET_BOOKS_PAGE,
  GET_BOOKS_PAGE_SUCCESS,
  GET_BOOKS_PAGE_ERROR,
  ADD_BOOK,
  ADD_BOOK_SUCCESS,
  ADD_BOOK_ERROR,
  RECOMMEND_BOOK,
  RECOMMEND_BOOK_SUCCESS,
  RECOMMEND_BOOK_ERROR,
  EDIT_RECOMMENDATION,
  EDIT_RECOMMENDATION_SUCCESS,
  EDIT_RECOMMENDATION_ERROR,
  DELETE_RECOMMENDATION,
  DELETE_RECOMMENDATION_SUCCESS,
  DELETE_RECOMMENDATION_ERROR,
} from './actions';

const initialState = {
  loading: false,
  lastUpdated: 0,
  books: [],
  errorMessage: '',
  cursorSlug: '',
  status: 'none',
  newRecommendations: [],
  signal: '',
};

function booksState(state = initialState, action) {
  switch (action.type) {
    case GET_BOOKS:
      return {
        ...state,
        loading: true,
      };
    case GET_BOOKS_SUCCESS:
      return {
        ...state,
        loading: false,
        lastUpdated: (new Date()).getTime(),
        books: action.books,
      };
    case GET_BOOKS_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case GET_BOOKS_PAGE:
      return {
        ...state,
        loading: true,
      };
    case GET_BOOKS_PAGE_SUCCESS:
      const cursorSlug = action.data.data.books.slice(-1).pop().serial;

      return {
        ...state,
        loading: false,
        lastUpdated: (new Date()).getTime(),
        books: state.books.concat(action.data.data.books),
        cursorSlug
      };
    case GET_BOOKS_PAGE_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case ADD_BOOK:
      return {
        ...state,
        loading: true,
      };
    case ADD_BOOK_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_BOOK_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case RECOMMEND_BOOK:
      return {
        ...state,
        loading: true,
      };
    case RECOMMEND_BOOK_SUCCESS:
      const newRecommendation = {
        ...action.args,
        user: action.state.profileState.profile,
        id: 'new',
      };

      let books = [];

      if (state.books.length > 0) {
        books = state.books;
        const index = state.books.findIndex(b => b.id === action.args.bookId);

        books[index].recommendations.push(newRecommendation);
      }

      return {
        ...state,
        loading: false,
        books: books,
        lastUpdated: (new Date()).getTime(),
      };
    case RECOMMEND_BOOK_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case EDIT_RECOMMENDATION:
      return {
        ...state,
        loading: true,
      };
    case EDIT_RECOMMENDATION_SUCCESS:
      let allBooks;

      try {
        const bookIndex = state.books.findIndex(b => b.id === action.args.bookId);
        const book = state.books[bookIndex];

        const recommendationIndex = book.recommendations.findIndex(r => r.id === action.args.id);
        const recommendation = book.recommendations[recommendationIndex];

        recommendation.text = action.args.text;

        book.recommendations[recommendationIndex] = recommendation;

        allBooks = state.books;
        allBooks[bookIndex] = book;
      } catch (e) {
        allBooks = state.books;
      }

      return {
        ...state,
        loading: false,
        books: allBooks,
        lastUpdated: (new Date()).getTime(),
      };
    case EDIT_RECOMMENDATION_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };

    case DELETE_RECOMMENDATION:
      return {
        ...state,
        loading: true,
      };
    case DELETE_RECOMMENDATION_SUCCESS:
      // TODO(shane): remove from state
      // console.log('books state', state);
      // console.log('args', action.args);

      return {
        ...state,
        loading: false,
        lastUpdated: (new Date()).getTime(),
      };
    case DELETE_RECOMMENDATION_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export default booksState;
