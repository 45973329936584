import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import ConnectedComponent from '../ConnectedComponent';

function Alert(props) {
  return <MuiAlert elevation={6} variant="standard" {...props} />;
}

function Messages({ messagesState, hideSuccessMessage }) {
  return (
    <div>
      <Snackbar 
        open={messagesState.successMessageOpen}          
        autoHideDuration={6000} onClose={hideSuccessMessage}            
      >
        <Alert 
          onClose={hideSuccessMessage} 
          severity="success"            
        >
          {messagesState.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ConnectedComponent(Messages);
