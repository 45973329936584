import { createMuiTheme }  from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#487af7',
            main: '#1B59F6',
            dark: '#123eac',
            contrastText: '#fff',
        },
        secondary: {
            light: '#4be1a5',
            main: '#1FDA8F',
            dark: '#159864',
            contrastText: '#292A2C',
        },
        main: {
          dark: '#000000',
        },
        background: {
          default: '#FFFFFF',
        },
    },
    overrides: {
      MuiButton: {
        // Name of the styleSheet
        root: {
          // Name of the rule
          textTransform: 'none',                          
        },
        contained: {
          boxShadow: '0 3px 4px rgba(0,0,0,.04), 0 1px 1px rgba(1,0,0,.1)',            
        },    
        outlinedPrimary: {
          backgroundColor: 'rgba(27, 89, 246, 0.1)',
          border: '1px solid rgba(27, 89, 246, 0.2)',
        }
      },
      MuiLink: {        
        root: {          
          fontWeight: 'bold',                          
        },        
      } ,
      button: {
        '& > *': {
          margin: 4,
        },
      }, 
      MuiInputBase: {
        input: {
          lineHeight: '1.5em',
        },
      },           
    },
    typography: {
      fontFamily: [
        'Lato',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      h1: {                
      },
      h3: {
        fontWeight: 900,
        fontSize: '2.5rem', 
        [breakpoints.down('sm')]: {
          fontSize: '2rem',      
        },
      },
      h4: {
        fontWeight: 900,
      },
      h5: {
        fontWeight: 700,
      },
    },              
  },       
)

export default theme