export function asyncStateFunction(stateName, startMessage, successMessage, errorMessage, method, args, wait = true) {
  return (dispatch, getState) => {
    if (wait && getState()[stateName].loading) {
      return Promise.resolve();
    }

    if ('lastUpdated' in getState()[stateName]) {
      const sinceLastCall = (new Date()).getTime() - getState()[stateName].lastUpdated;
      if (sinceLastCall < 1500) {
        return Promise.resolve();
      }
    }

    // console.log('dispatch', startMessage);
    dispatch({
      type: startMessage,
    });

    method(args)
        .then((response) => {
          dispatch({
            type: successMessage,
            data: response,
            args: args,
            state: getState(),
          });
        })
        .catch(error => {
          console.log('error', error);
          dispatch({
            type: errorMessage,
            message: error,
          });
        });
  };
}