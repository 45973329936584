import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import { PublicProfileService } from '../../services/publicProfile';

import ConnectedComponent from '../../components/ConnectedComponent';

import Header from '../../components/Header/Header';
import UserInformation from '../../components/UserProfile/UserInformation';
import UserLoggedOutBanner from '../../components/UserProfile/UserLoggedOutBanner';
import UserRecommendedBooks from '../../components/UserProfile/UserRecommendedBooks';
import Messages from '../../components/Messages/Messages';


function PublicProfile({ booksState, match, location }) {
  const username = match.params.username;

  const [profile, setProfile] = useState({
    bio: '',
    company: '',
    location: '',
    name: '',
    photo: '',
    role: '',
    username: '',
    recommendations: []
  });

  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState((new Date()).getTime());
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    (async function() {
      // console.log(match.params.username);

      setBooks([]);
      setLoading(true);
      const data = await PublicProfileService.getPublicProfile({ username: match.params.username });
      setLoading(false);

      if (data.data.profile) {
        const { bio, company, location, name, photo, role, recommendations } = data.data.profile;
        setProfile({ bio, company, location, name, photo, role, username });
        const books = recommendations.map(r => r.book);
        setBooks(books);
      } else {
        setNotFound(true);
      }
    })();
  }, [location]);

  useEffect(() => {
    if (booksState.lastUpdated !== lastUpdated) {
      (async function() {
        setLoading(true);
        const data = await PublicProfileService.getPublicProfile({ username: match.params.username });
        setLoading(false);

        if (data.data.profile) {
          const { bio, company, location, name, photo, role, recommendations } = data.data.profile;
          setProfile({ bio, company, location, name, photo, role, username });
          const books = recommendations.map(r => r.book);
          setBooks(books);
        } else {
          setNotFound(true);
        }
      })();
    }
  }, [booksState.lastUpdated])

  console.log('profile', profile)

  return (
    <div>
      <Header></Header>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="md" style={notFound ? { display: 'none' } : {}}>
          <UserInformation profile={profile} loading={loading} />
          <UserLoggedOutBanner loading={loading} />          
          <UserRecommendedBooks profile={profile} books={books} loading={loading} />
        </Container>
        <Container maxWidth="md" style={notFound ? {marginTop: 100} : { display: 'none' }}>
          This user was not found! <Link to="/">Let's go back.</Link>
        </Container>
        <Messages></Messages>
      </React.Fragment>
    </div>
  );
}

export default ConnectedComponent(PublicProfile);
