import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import ConnectedComponent from '../../components/ConnectedComponent';

import Header from '../../components/Header/Header';
import UserEditInformation from '../../components/UserEditProfile/UserEditInformation';
import Messages from '../../components/Messages/Messages';

function Profile({ profileState, history, logOut, setUsername, setProfile, showSuccessMessage, resetState }) {
  // React.useEffect(() => {
  //   if (profileState.lastState === 'SET_PROFILE_SUCCESS') {
  //     showSuccessMessage('Profile successfully updated');
  //     history.push(`/u/${profileState.profile.username}`);
  //     resetState();
  //   }
  // }, [profileState.lastState]);

  return (
    <div>
      <Header></Header>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="md">
          <UserEditInformation
              profile={profileState.profile}
              loading={profileState.loading}
              handleSetUsername={setUsername}
              handleSave={setProfile} />
        </Container>
      </React.Fragment>
      <Messages></Messages>
    </div>
  );
}

export default ConnectedComponent(Profile);
