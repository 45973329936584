import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import ConnectedComponent from '../../components/ConnectedComponent';

import Header from '../../components/Header/Header';
import Messages from '../../components/Messages/Messages';
import UserTile from '../../components/UserTile/UserTile';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '82px',
  },
  title: {
    margin: '0px 24px 18px 0px',
    paddingTop: '24px',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      margin: '0px 24px 18px 24px',
      fontSize: '1.625rem',
    },
  },
  subtitle: {
    margin: '0px 24px 18px 0px',
    paddingTop: 0,
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.54)',
    width: '65%',
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      margin: '0px 24px 18px 24px',
      fontSize: '1.225rem',
      width: 'auto',
    },
  },
}));

function AllUser({ userList, usersState }) {
  useEffect(() => {
    userList()
  }, [])

  const matches = useMediaQuery('(min-width:600px)');

  const classes = useStyles();

  useEffect(() => {
    console.log('users', usersState.users)
  })

  const goodUsers = usersState.users.filter(u => u.role)

  const goodProfileGoodRecos = goodUsers.filter(u => u.recommendations.length > 0)
  const goodProfileBadRecos = goodUsers.filter(u => u.recommendations.length === 0)

  const badUsers = usersState.users.filter(u => !u.role)

  return (
    <div className={classes.root} >
      <Header></Header>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth='lg'>

        <Typography gutterBottom variant="h4" component="h1" className={classes.title} >
          All Users
        </Typography>
        <Typography variant="h5" component="h2" className={classes.subtitle} >
          Browse all users on PM Library
        </Typography>


        <Grid
          container
          spacing={2}
          nowrap={'nowrap'}
          direction="row"
          justify={matches ? ("flex-start") : ("center") }
          alignItems="flex-start">
          {goodProfileGoodRecos.map((user, i) => <UserTile user={user} key={i} />)}
          {goodProfileBadRecos.map((user, i) => <UserTile user={user} key={i} />)}
          {badUsers.map((user, i) => <UserTile user={user} key={i} />)}
        </Grid>

        </Container>
      </React.Fragment>
      <Messages></Messages>
    </div>
  );
}

export default ConnectedComponent(AllUser);
