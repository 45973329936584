import {
  SUBMIT_FEEDBACK,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_ERROR,
} from './actions';

const initialState = {
  loading: false,
};

function feedbackState(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_FEEDBACK:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        books: action.books,
      };
    case SUBMIT_FEEDBACK_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    default:
      return state;
  }
}

export default feedbackState;
