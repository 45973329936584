import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  SET_PROFILE,
  SET_PROFILE_SUCCESS,
  SET_PROFILE_ERROR,
  SET_USERNAME,
  SET_USERNAME_SUCCESS,
  SET_USERNAME_ERROR,
} from './actions';

const initialState = {
  loading: false,
  lastUpdated: 0,
  profile: {
    bio: '',
    company: '',
    location: '',
    name: '',
    photo: '',
    role: '',
    username: '',
  },
  lastState: '',
  errorMessage: '',
};

function profileState(state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.data.data.myProfile,
      };
    case GET_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case SET_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case SET_PROFILE_SUCCESS:
      const profile = {
        ...state.profile,
        ...action.args,
      }
      return {
        ...state,
        loading: false,
        lastState: SET_PROFILE_SUCCESS,
        profile
        // profile: action.profile,
      };
    case SET_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case SET_USERNAME:
      return {
        ...state,
        loading: true,
      };
    case SET_USERNAME_SUCCESS:
      return {
        ...state,
        loading: false,
        lastState: SET_USERNAME_SUCCESS,
        profile: {
          ...state.profile,
          username: action.args.username,
        },
      };
    case SET_USERNAME_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case 'RESET':
      return {
        ...state,
        lastState: '',
      }
    default:
      return state;
  }
}

export default profileState;
