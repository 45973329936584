import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Button from '@material-ui/core/Button';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';


import ConnectedComponent from '../ConnectedComponent';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 260,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
        border: '3px solid #F6F6F6',
        marginBottom: 24,
        [theme.breakpoints.down('xs')]: {
          margin: 'auto',
          height: 'auto',
          // maxWidth: '100%',
        },
    },
    media: {
        width: "100%",
        backgroundColor: '#F6F6F6',
        paddingTop: 12,
        paddingBottom: 12,
    },
    img: {        
        width: 'auto',
        height: 100,
        margin: 'auto',
        maxHeight: 100,
        maxWidth: 100,
        objectFit: 'cover',
        borderRadius: 100,
    },
    img_book: {
      height: 450,
      objectFit: 'contain',
      width: 'initial',
      margin: 'auto',
      borderRadius: 100,
    },
    img_book_tiles: {
      top: '0%',
      transform: 'none',
      height: '100%',
      width: 'auto',
      textAlign: 'center',
      margin: 'auto',
      position: 'relative',
      display: 'block',
      left: 0,
      borderRadius: 2,
    },
    actions: {
        display: 'flex',
        marginTop: 'auto',
    },
    actionArea: {
        height: 184,
        width: 250,
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
          height: 'auto',
        },
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        lineHeight: '1.25',        
        textTransform: 'capitalize',        
    },
    subtitle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        lineHeight: '26px',
    },
    body1: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
    },
    gridList: {
      // height: 116,
      backgroundColor: '#f6f6f6',
      paddingTop: 6,
    },
    viewUser: {
      margin: '12px auto 12px',
    },
    listTileBook: {
      paddingTop: '6px!important',
      paddingRight: '0px!important',
      paddingLeft: '0px!important',
      paddingBottom: '6px!important',
      height: '116px!important',      
    },    
}));

function UserTile({ user, history }) {
  const classes = useStyles();

  return (
    <Grid item >
      <Grid container >

        <Card
          className={classes.root}
          variant="outlined"
        >
          <CardActionArea 
            onClick={() => history.push(`/u/${user.username}`)}                     
          >
            <div className={classes.media}>
              <CardMedia
                component="img"
                alt={user.name}
                image={user.photo}
                title={user.name}
                className={classes.img}
              />
            </div>
            <CardContent className={classes.actionArea}>
              <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                {user.name}
              </Typography>
              <Typography variant="body1" color="textSecondary" component="p">                
                {user.role && 
                  <Typography variant="body1" color="textSecondary" component="span">{user.role} </Typography>
                }
                {user.company && 
                  <Typography variant="body1" color="textSecondary" component="span">@ {user.company} </Typography>
                }
                {user.location && 
                  <Typography variant="body1" color="textSecondary" component="span">({user.location})</Typography>
                }
              </Typography>

              <Button
                className={classes.viewUser}
                size="small"
                variant="outlined"
                color="default"
                disableElevation
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={() => history.push(`/u/${user.username}`)}
                startIcon={<ArrowForwardRoundedIcon />}
              >
                View user
              </Button>
            </CardContent>

            <GridList cellHeight={100} className={classes.gridList} cols={3}>
              {user.recommendations.slice(0, 3).map((book) => (
                <GridListTile key={book.book.title} className={classes.listTileBook} >
                  <img src={book.book.cover} alt={book.book.title} className={classes.img_book_tiles} />
                </GridListTile>
              ))}
            </GridList>

          </CardActionArea>


        </Card>
      </Grid>
    </Grid>
  );
}

export default ConnectedComponent(UserTile);