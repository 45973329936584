import { AuthService } from './auth';
import { ApolloService } from './lib/apollo';
import { Analytics } from './firebase';
import gql from 'graphql-tag';

class RecommendationsService {
  static async recommendBook({ bookId, text }) {
    // console.log('recommend book', bookId, text);
    let user;

    try {
      user = await AuthService.getUser();
    } catch (error) {
      console.error('Could not get user')
      console.error(error);
    }

    const client = await ApolloService.client(user.authToken);
    const mutation = gql`
      mutation AddRecommendation($bookId: String, $text: String) {
        addRecommendation(bookId: $bookId, text: $text)
      }
    `;

    const variables = { bookId, text };

    Analytics.logEvent('add_recommendation', { bookId });

    return client.mutate({ mutation, variables });
  }

  static async editRecommendation({ bookId, text }) {
    let user;

    try {
      user = await AuthService.getUser();
    } catch (error) {
      console.error('Could not get user')
      console.error(error);
    }

    const client = await ApolloService.client(user.authToken);

    const mutation = gql`
      mutation EditRecommendation($bookId: String, $text: String) {
        editRecommendation(bookId: $bookId, text: $text)
      }
    `;

    const variables = { bookId, text };

    Analytics.logEvent('edit_recommendation', { bookId });

    return client.mutate({ mutation, variables });
  }

  static async deleteRecommendation({ bookId }) {
    // console.log('deleting for bookId', bookId)
    let user;

    try {
      user = await AuthService.getUser();
    } catch (error) {
      console.error('Could not get user')
      console.error(error);
    }

    const client = await ApolloService.client(user.authToken);

    const mutation = gql`
      mutation DeleteRecommendation($bookId: String) {
        deleteRecommendation(bookId: $bookId)
      }
    `;

    const variables = { bookId };

    Analytics.logEvent('delete_recommendation', { bookId });

    return client.mutate({ mutation, variables });
  }
}

export { RecommendationsService };
