import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import logoBig from '../../assets/logo-pm-library_s.png';
import step1 from '../../assets/step1.png';
import step2 from '../../assets/step2.png';
import step3 from '../../assets/step3.png';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ConnectedComponent from '../../components/ConnectedComponent';


const root = {
  height: '100vh',
  minWidth: 400,
}

const header = {
  display: 'flex',
  boxSizing: 'box-border',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
}

const paper = {
  display: 'flex',
  boxSizing: 'box-border',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: 8,
  border: '3px solid #F6F6F6',
  height: 500,
}

const logoDesktop = {
  height: '60px',
  margin: "24px 0px 72px",
}

const image = {
  height: 200,
  width: 300,
  marginBottom: 24,
  marginTop: 6,
}

const intro = {
  width: 300,
  marginTop: 12,
}

const skip = {
  color: "#333333",
  paddingBottom: 72,
}

const submit = {
  margin: "24px 0px",
}

const container = {
  margin: "auto",
  width: "100%",
  maxWidth: 1100,
  flexGrow: 0,
}

const item = {
  width: "33%",
}

const number = {
  color: "rgb(51, 51, 51)",
  border: "3px solid #f6f6f6",
  borderRadius: 100,
  width: 40,
  height: 40,
  padding: "1px 0px 0px 0px",
  background: "#f6f6f6",
  marginBottom: 12,
}

function NewUser({ history }) {

  return (
    <div>
      <Grid  style={root}>
        <CssBaseline />
        <Grid item fullwidth >
          <div style={header}>
            <Box >
              <img alt="PM Library Logo" src={logoBig} style={logoDesktop} />
            </Box>
          </div>
        </Grid>
        <Grid container spacing={1} style={container} justify="center" alignItems="center"  >
          <Grid item xs={12} sm={4} style={item} zeroMinWidth>
            <div style={paper}>
              <Box >
                <img alt="PM Library Logo" src={step1} style={image} />
              </Box>
              <Typography component="h2" variant="p" style={number}>
                1
              </Typography>
              <Typography component="h1" variant="h4" gutterBottom>
                Fill your profile
              </Typography>
              <Typography component="p" variant="body1" align="center" display="block" style={intro}>
                To get started, tell the community who you are and what you do.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={submit}
                href="/profile"
                startIcon={<ArrowForwardRoundedIcon />}
              >
                Start here
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} style={item} zeroMinWidth>
            <div style={paper}>
              <Box >
                <img alt="PM Library Logo" src={step2} style={image} />
              </Box>
              <Typography component="h2" variant="p" style={number}>
                2
              </Typography>
              <Typography component="h1" variant="h4" gutterBottom>
                Get inspired
              </Typography>
              <Typography component="p" variant="body1" align="center" display="block" style={intro}>
                Discover book recommendations by real people from the tech and startup world.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} style={item} zeroMinWidth >
            <div style={paper}>
              <Box >
                <img alt="PM Library Logo" src={step3} style={image} />
              </Box>
              <Typography component="h2" variant="p" style={number}>
                3
              </Typography>
              <Typography component="h1" variant="h4" gutterBottom>
                Inspire others
              </Typography>
              <Typography component="p" variant="body1" align="center" display="block" style={intro}>
                Recommend your favourite books to others on the platform and on social media.
              </Typography>
            </div>
          </Grid>
          <Grid item fullwidth >
          <div style={header}>
            <Box >
              <Typography component="p" variant="body1" align="center" display="block" style={intro}>
                <a href="/" style={skip}>Skip for now</a>
              </Typography>
            </Box>
          </div>
        </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ConnectedComponent(NewUser);