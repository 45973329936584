import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import SendRoundedIcon from '@material-ui/icons/SendRounded';

import { submitFeedback } from '../../state/feedback/actions';
import { showSuccessMessage } from '../../state/messages/actions';

const useStyles = makeStyles((theme) => ({
    formRoot: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 'calc(100% - 8px)',
      },
    },
    root: {
      display: 'flex',
      marginLeft: '0px!important',
      marginRight: 4,
    },
    listItemIcon: {
      minWidth: '26px',
      color: "rgba(0, 0, 0, 1)",
    },
    menu: {
      backgroundColor: 'rgba(1, 1, 1, 0.2)',
    },
    dialogActionsRight: {
      justifyContent: "flex-end",
    },
    button: {
      top: '75%',
      position: 'fixed',
      right: '-44px',
      WebkitTransform: 'rotate(270deg)',
      MozTransform: 'rotate(270deg)',
      OTransform: 'rotate(270deg)',
      msTransform: 'rotate(270deg)',
      transform: 'rotate(270deg)',
      borderRadius: '8px 8px 0px 0px',
      zIndex: 99,
    },
  }));


function FeedbackDialogComponent({ submitFeedback, showSuccessMessage, loggedin }) {
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState('');
  const [buttonsDisabled, setButtonsDisabled] = React.useState(false);

  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setText('');
    setOpen(false);
  };

  const handleSubmit = () => {
    setButtonsDisabled(true);
    submitFeedback(text);
    showSuccessMessage('Thank you! Your feedback was submitted.');
    setText('');
    setOpen(false);
  }

  return (
    <div className={classes.root}>
      {loggedin ?
        <Button
          variant="contained"
          aria-controls="simple-menu"
          aria-haspopup="true"
          color="primary"
          size="middle"
          disableElevation
          className={classes.button}
          onClick={handleClickOpen}
        >
          Give Feedback
        </Button>
      :
        <Button
          variant="contained"
          color="primary"
          size="middle"
          disableElevation
          className={classes.button}
          href="mailto:alex@thepmlibrary.com"
        >
          Give Feedback
        </Button>
      }

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Feedback to the team</DialogTitle>
        <DialogContent>
          <FormGroup row>
            <form className={classes.formRoot} noValidate autoComplete="off">
              <DialogContentText>
                Thanks for taking the time to give us valuable feedback. This could be everything.
              </DialogContentText>
              <TextField
                id="outlined-multiline-static"
                label="Your Feedback"
                multiline
                rows={8}
                placeholder="I would love to see xyz! I'm struggling with ..."
                variant="outlined"
                value={text}
                onChange={e => setText(e.target.value)}
              />
            </form>
          </FormGroup>
        </DialogContent>

        <DialogActions className={classes.dialogActionsRight}>
          <Button
            disabled={buttonsDisabled}
            onClick={handleSubmit}
            size="small"
            variant="contained"
            color="primary"
            disableElevation
            startIcon={<SendRoundedIcon />}
          >
            Send
          </Button>
          <Button
            disabled={buttonsDisabled}
            onClick={handleClose}
            size="small"
            variant="outlined"
            color="default"
            disableElevation
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  submitFeedback: text => dispatch(submitFeedback(text)),
  showSuccessMessage: message => dispatch(showSuccessMessage(message)),
});

const FeedbackDialog = connect(
  null,
  mapDispatchToProps
)(FeedbackDialogComponent);

export { FeedbackDialog };