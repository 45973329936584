import { asyncStateFunction } from '../util';
import { FeedbackService } from '../../services/feedback';

export const SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK';
export const SUBMIT_FEEDBACK_SUCCESS = 'SUBMIT_FEEDBACK_SUCCESS';
export const SUBMIT_FEEDBACK_ERROR = 'SUBMIT_FEEDBACK_ERROR';

export const submitFeedback = opts => asyncStateFunction(
  'booksState',
  SUBMIT_FEEDBACK,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_ERROR,
  FeedbackService.submitFeedback,
  opts);