import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import logoBig from '../../assets/logo-pm-library_s.png';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import ConnectedComponent from '../../components/ConnectedComponent';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://thepmlibrary.com/books/">
        PM Library
      </Link>{' '}
      {new Date().getFullYear()}      
    </Typography>
  );
}

const root = {
  height: '100vh',
}
const image = {
  backgroundImage: "url('/pm-library-books.png')",
  backgroundRepeat: 'no-repeat',
  backgroundColor: 'grey',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}
const paper = {
    
  display: 'flex',
  boxSizing: 'box-border',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',    
}
const form = {
  width: '80%%', // Fix IE 11 issue.
  marginTop: 32,
}
const submit = {
  margin: "6px 0px",   
}
const logoDesktop = {  
  height: '60px',
  margin: "24px 0px 96px",
}
const intro = {  
  width: 300,
  marginTop: 12,
}

function Login({ logInGoogle }) {
  return (
    <div>
      <Grid container component="main" style={root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} style={image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div style={paper}>                                        
            <Box >
              <img alt="PM Library Logo" src={logoBig} style={logoDesktop} />                           
            </Box>
            <Typography component="h1" variant="h4" gutterBottom>
              Be part of the first<br/>social library!
            </Typography>
            <Typography component="p" variant="body1" align="center" display="block" style={intro}>
            Exchange inspiring book recommendations with like-minded people from the tech and start-up community.
            </Typography>
            <form style={form} noValidate>
              {/* <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />                 */}
              <Button 
                variant="contained" 
                color="primary" 
                style={submit}                  
                onClick={logInGoogle}  
                fullWidth
                startIcon={<PersonAddRoundedIcon />}      
              >                  
                Join free with Google
              </Button>
              <Button 
                variant="outlined" 
                color="default" 
                style={submit}                  
                onClick={logInGoogle}
                fullWidth                            
              >                  
                Log in
              </Button>
              <Grid container>
                {/* <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid> */}
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
  
export default ConnectedComponent(Login);
